import React, { useState } from "react";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/i-exp-img4.png";
import Servexp2 from "../EXPimages/dm-bank6-img6.png";
import { Link, useNavigate } from "react-router-dom";

const Domgm5 = () => {
  const nav = useNavigate();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <h1 className="XS-sec1-two-h1">GAMING AND ENTERTAINMENT</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Powering Gaming and Entertainment with Innovative IT Solutions
          </h1>
          <p className="XS-sec1-two-p1">
            From customized software development to cloud solutions and
            cybersecurity, we have the expertise and experience to help you
            navigate the constantly evolving landscape of the gaming industry
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Gaming-Specific IT Solutions
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Creation of Immersive Experiences for customers
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Entertainment Industry IT Solutions
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Experts in Gaming-Specific and Entertainment Industry IT Solutions
          </h1>
          <p className="XS-sec1-two-p-p1">
            We offer customized solutions for gaming businesses and the
            entertainment industry. We will work with you in the areas of game
            development, server management, network infrastructure, video
            production, post-production, live event streaming, ticketing
            solutions, and emerging technologies such as augmented reality,
            virtual reality, and artificial intelligence.
          </p>

          <Link className="XS-sec1-two-btn1" to={`/contactus`}>
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          {/* <button className="XS-sec1-two-btn1">
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button> */}

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span> What kind of IT solutions
            can you provide for my gaming business?
          </p>
          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              We can provide customized solutions for game development, server
              management, network infrastructure, and other needs specific to
              the gaming industry.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> Can you help me with video production and live for my
            entertainment business?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              Yes, we have expertise in these areas and can provide tailored
              solutions to meet your needs.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> How can you help me create immersive experiences for
            my customers?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              We can leverage emerging technologies such as augmented reality,
              virtual reality, and artificial intelligence to create truly
              immersive experiences for your customers.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> Do you offer IT solutions for businesses outside of
            gaming and entertainment?
          </p>
          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              Yes, we can provide customized IT solutions for businesses in a
              variety of industries.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Banking</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link className="XS-sec2-in2-d1-sp1" to={`/domains/dombank6`}>
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Domgm5;
