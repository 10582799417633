import React from "react";

import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import HDM from "../EXPimages/Ham-menu.png";

const Header = () => {
  return (
    <>
      <nav>
        <div className="n-left">
          <div className="logo-container">
            <Link className="brand" to="/">
              teksett
            </Link>
            <img className="c-logo" src="./PBimages/hf-logo-img1.png" />
          </div>
          <span className="H-sec-SL">l</span>

          <Link className="hd-sec1-bt" to="/htabs">
            <img className="tabn-hbm" src="./PBimages/Ham-menu.png" />
          </Link>

          <img className="i-usa" src="./PBimages/hd-usa.png" />
        </div>

        <div className="nav-list">
          <ul>
            <li>
              {" "}
              <NavLink to="/" activeClassName="active">
                Home
              </NavLink>
            </li> 
            <li>
              {" "}
              <NavLink to="/aboutus" activeClassName="active">
                About Us
              </NavLink>
            </li>
            <li>
              {" "}
              <NavLink to="/services" activeClassName="active">
                Services
              </NavLink>
            </li>
            <li>
              {" "}
              <NavLink to="/domains" activeClassName="active">
                Domains
              </NavLink>
            </li>
            <li>
              {" "}
              <NavLink to="/insights" activeClassName="active">
                Insights
              </NavLink>
            </li>

            <li>
              {" "}
              <NavLink to="/products" activeClassName="active">
                Products
              </NavLink>
            </li>


            <li>
              {" "}
              <NavLink to="/careers" activeClassName="active">
                Careers
              </NavLink>
            </li>

            <li>
              {" "}
              <NavLink to="/blogs" activeClassName="active">
                Blogs
              </NavLink>
            </li>

          </ul>
        </div>

        {/* <div className="c-page"> */}
        <Link className="ctcn" to="/contactus">
          Contact Us
        </Link>

        {/* <p>Contact Us</p> */}

        {/* <section id="n-right-one">
            <p>E-Mail</p>
            <p>Tel.</p>
          </section>

          <section id="n-right-s1">
            <p>bsetti10@gmail.com</p>
            <p>+14025075290</p>
          </section>

          <span className="H-divder">|</span>

          <section>
            <p>Contact Us</p>
          </section> */}
        {/* </div> */}
      </nav>
    </>
  );
};

export default Header;
