import React from "react";

import "./Ser1.css";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img10.png";
import Servexp2 from "../EXPimages/S-sec2-img1.png";
import { useNavigate } from "react-router-dom";

const Serv11de = () => {
  const nav = useNavigate();

  const handleItSolutionsDelivery = () => {
    nav("/services/ser1");
  };

  const handleTouch = () => {
    nav("/contactus");
  };

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to services
          </span>
          <h1 className="XS-sec1-two-h1">DATA ENGINEERING</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Experience The True Potential of Your Data
          </h1>
          <p className="XS-sec1-two-p1">
            Our expert team harnesses cutting-edge technologies to transform raw
            data into actionable insights, driving your business forward with
            efficiency, accuracy, and innovation.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Advanced Data Analytics
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Data Quality and Integrity
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Scalable Data Solutions
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why choose Teksett for your data engineering?
          </h1>
          <p className="XS-sec1-two-p-p1">
            Choose Teksett as your trusted partner for data engineering. Our
            team of experts excels in turning complex data into valuable
            insights, while implementing robust master data management [MGM]
            practices. With our proven track record and commitment to
            excellence, we empower your organization to unlock the full
            potential of your data assets..
          </p>

          <button className="XS-sec1-two-btn1" onClick={handleTouch}>
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>How can Teksett's data
            engineering services help improve data quality and accuracy?
          </p>

          <p className="XS-sec1-two-sp-pA">
            Teksett's data engineering expertise includes implementing master
            data management (MDM) practices, ensuring data integrity,
            consistency, and accuracy across your organization's various data
            sources.
          </p>
          <hr></hr>

          <p className="XS-sec1-two-sp-ps">
            <span>+</span> Can Teksett handle large-scale data integration and
            consolidation projects as part of their data engineering services?
          </p>

          <p className="XS-sec1-two-sp-pA">
            Absolutely. Teksett specializes in managing complex data integration
            and consolidation initiatives, ensuring seamless data flow and
            integrity across systems and applications.
          </p>
          <hr></hr>

          <p className="XS-sec1-two-sp-ps">
            <span>+</span>
            How does Teksett ensure data security and privacy while performing
            data engineering tasks?
          </p>

          <p className="XS-sec1-two-sp-pA">
            Teksett follows industry best practices and stringent security
            protocols to protect data during data engineering processes,
            ensuring confidentiality and compliance with data protection
            regulations.
          </p>
          <hr></hr>

          <p className="XS-sec1-two-sp-ps">
            <span>+</span> What methodologies or technologies does Teksett
            employ to ensure accurate and up-to-date data management?
          </p>

          <p className="XS-sec1-two-sp-pA">
            Teksett utilizes advanced data governance frameworks, data cleansing
            techniques, and data validation processes to maintain accurate and
            up-to-date data, resulting in reliable and trustworthy data assets.
          </p>
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>IT Solutions delivery</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>
            <span
              className="XS-sec2-in2-d1-sp1"
              onClick={handleItSolutionsDelivery}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv11de;
