import React, { useState } from "react";
import "./Ser1.css";
import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/service-exp1-itsd1-img1.png";
import Servexp2 from "../EXPimages/service-sts2-img2.png";
import Servexptbv from "../EXPimages/S-exp-tbv-img1.png";

import { Link, useNavigate } from "react-router-dom";

const Ser1 = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }
  const nav = useNavigate();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">
            IT Solutions <span className="XS-sec1-h1-sp"> Delivery</span>
          </h1>
          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexptbv}
            alt="expimg"
          />
          <p className="XS-sec1-two-p1">
            At Teksett, we understand that your business relies on efficient and
            effective IT solutions. That's why we offer a comprehensive range of
            services to help you streamline your operations, reduce costs, and
            maximize productivity.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            High level of expertise{" "}
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Customized IT Solutions
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Collaborative approach to work
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            We can deliver top <span> IT Solutions to you</span>
          </h1>
          <p className="XS-sec1-two-p-p1">
            We offer customized IT solutions that are tailored to meet the
            specific needs of your business. We also strive to take a
            collaborative approach, working closely with clients every step of
            the way to ensure the best possible results.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
            onClick={handleScrollToTop}
          >
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>What types of customized IT
            solutions do you offer?
          </p>

          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              Teksett's IT staffing service works by providing businesses with
              access to a pool of top IT talent. We pre-screen and qualify
              candidates to ensure that they meet your requirements, and we
              provide flexibility in hiring for specific projects or periods of
              time.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span>How do you ensure that your IT solutions will work for
            my business?
          </p>

          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              Hiring full-time IT professionals can be expensive, especially for
              small and medium-sized businesses. You'll only pay for the time
              and expertise that you need, without the added costs of benefits,
              taxes, and other expenses associated with hiring full-time
              employees.
            </p>
          )}

          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span>Can you help my business scale its IT infrastructure
            as it grows?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              We can help your business reduce hiring time by providing a range
              of pre-screened and qualified candidates. The time it takes to
              hire an IT professional will depend on the specific requirements
              of your project and the availability of qualified candidates. Our
              team of experts will work with you to find the right candidate as
              quickly as possible based on our offshore and on-site models.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span>Do you offer ongoing IT support and maintenance
            services?
          </p>
          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              Teksett's IT staffing service works by providing businesses with
              access to a pool of top IT talent. We pre-screen and qualify
              candidates to ensure that they meet your requirements, and we
              provide flexibility in hiring for specific projects or periods of
              time.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Staff Augmentation services</h1>
            <p className="XS-sec2-in2-d1-p1">
              Our IT staffing services offer a range of solutions to help you
              find the right talent for your business needs. From web developers
              to IT recruiters, our team of experts can help you find the
              perfect candidate to fill your IT staffing requirements.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/services/serv2sas`}
              onClick={handleScrollToTop}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ser1;
