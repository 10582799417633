import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Ser1.css";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img4.png";
import Servexp2 from "../EXPimages/service-sv6-img6.png";

const Serv5od = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  const nav=useNavigate()
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">OFFSHORE DEVELOPMENT</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />
          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Teksett's Agile Solutions Drive Global Success
          </h1>
          <p className="XS-sec1-two-p1">
            Our skilled teams deliver tailored solutions, cost-efficiency, and
            seamless collaboration, driving your business forward in a
            competitive digital landscape, regardless of location.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Global Talent Access
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Cost-Effective Solutions
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Seamless Collaboration
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why Choose Teksett for your Offshore Development Solutions?
          </h1>
          <p className="XS-sec1-two-p-p1">
            Our expert team seamlessly delivers exceptional results, regardless
            of your geographical location. Benefit from our unrivaled
            collaboration, cutting-edge technologies, and cost-effective
            solutions
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
            onClick={handleScrollToTop}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>How does Teksett ensure
            effective communication and collaboration during offshore
            development projects?
          </p>
          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              At Teksett, we prioritize clear communication and collaboration.
              We utilize advanced project management tools, regular progress
              updates, and video conferencing to ensure transparent and
              efficient collaboration throughout the project.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> What measures does Teksett have in place to ensure
            data security during offshore development?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              Teksett takes data security seriously. We have stringent protocols
              in place, including encryption, restricted access controls, and
              robust data backup systems. Your sensitive information is
              safeguarded at every stage of the offshore development process.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> How does Teksett ensure the quality of offshore
            development deliverables?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              Quality is at the core of our offshore development services.
              Teksett follows rigorous quality assurance processes, including
              comprehensive testing, code reviews, and adherence to industry
              best practices. We ensure that all deliverables meet the highest
              standards of quality and performance.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span>How does Teksett handle time zone differences in
            offshore development projects?
          </p>
          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              Teksett understands the importance of addressing time zone
              differences. Our teams are flexible and adapt to overlapping
              working hours, ensuring effective communication and collaboration.
              We manage schedules efficiently to minimize any impact on project
              timelines.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Software VAR</h1>

            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/services/serv6svar`}
              onClick={handleScrollToTop}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv5od;
