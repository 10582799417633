import React, { useState } from "react";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/domain-exp1-h1-img1.png";
import Servexp2 from "../EXPimages/dm-ag2-img2.png";
import { Link, useNavigate } from "react-router-dom";

const Dom1 = () => {
  const nav =useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
 
  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />
          <h1 className="XS-sec1-two-h1">
            Streamlined IT solutions for<br></br> your
            <span> healthcare practice</span>
          </h1>
          <p className="XS-sec1-two-p1">
            With our expert IT services, your healthcare practice can focus on
            what matters most: providing exceptional patient care. Our expertise
            in the industry and range of IT services make us the perfect partner
            to take your healthcare business to the next level.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Streamline Patient Management
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Data Security and Compliance
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Healthcare operations optimization
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why Teksett for your <span> healthcare IT needs?</span>
          </h1>
          <p className="XS-sec1-two-p-p1">
            Our IT solutions provide custom software development for patient
            management, data security and compliance, and IT staff augmentation
            to optimize healthcare operations. Our tailored solutions can help
            reduce errors, increase efficiency, improve patient outcomes, and
            ensure compliance with regulatory organization
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          {/* <button className="XS-sec1-two-btn1">
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button> */}

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p  onClick={() => setIsOpen1(!isOpen1)}  className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>What types of customized IT
            solutions do you offer?
          </p>
          {isOpen1 && (
        
          <p className="XS-sec1-two-sp-pA">
            We offer a wide range of IT services for banks, including network
            infrastructure management, data security, cloud computing, software
            development, and IT consulting, among others.
          </p>
          )}
          <hr></hr>

          <p  onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span>What specific patient management processes can your IT
            solutions streamline?
          </p>
          {isOpen2 && (
          <p className="XS-sec1-two-sp-pA">
            We offer a wide range of IT services for banks, including network
            infrastructure management, data security, cloud computing, software
            development, and IT consulting, among others.
          </p>
          )}
          <hr></hr>

          <p  onClick={() => setIsOpen3(!isOpen3)}   className="XS-sec1-two-sp-ps">
            <span>+</span>How can your IT solutions ensure data security and
            compliance in the healthcare industry?
          </p>
          {isOpen3 && (
          <p className="XS-sec1-two-sp-pA">
            We offer a wide range of IT services for banks, including network
            infrastructure management, data security, cloud computing, software
            development, and IT consulting, among others.
          </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)}   className="XS-sec1-two-sp-ps">
            <span>+</span>How can your tailored software solutions improve
            patient outcomes?
          </p>
          {isOpen4 && (
          <p className="XS-sec1-two-sp-pA">
            We offer a wide range of IT services for banks, including network
            infrastructure management, data security, cloud computing, software
            development, and IT consulting, among others.
          </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>Agriculture</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Staff Augmentation services</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs of fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/services/serv2sas`}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
            {/* <span className="XS-sec2-in2-d1-sp1">
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span> */}
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dom1;