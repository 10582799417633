import React, { useState } from "react";
import "./Ser1.css";
import { Link, useNavigate } from "react-router-dom";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img1.png";
import Servexp2 from "../EXPimages/S-sec3-dt-img3.png";

const Serv2sas = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }
  const nav =useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">STAFF AUGMENTATION</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />
          {/* <h1 className="XS-sec1-two-h1-2">
            {" "}
            Build a World-class team with our Top IT experts
          </h1> */}
          <p className="XS-sec1-two-p1">
            Our IT staffing services offer a range of solutions to help you find
            the right talent for your business needs. From web developers to IT
            recruiters, our team of experts can help you find the perfect
            candidate to fill your IT staffing requirements.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Cost-efffective
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Reduce hiring time
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Access to top 5% talents
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why Choose Teksett for your IT Staffing needs?
          </h1>
          <p className="XS-sec1-two-p-p1">
            Our IT staffing services offer a cost-effective solution for small
            and medium-sized businesses to access top talent without the
            financial burden of hiring full-time employees. Our services provide
            flexibility, access to top talent, and reduced hiring time.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
            onClick={handleScrollToTop}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>How does Teksett's IT
            staffing service work?
          </p>

          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              Teksett's IT staffing service works by providing businesses with
              access to a pool of top IT talent. We pre-screen and qualify
              candidates to ensure that they meet your requirements, and we
              provide flexibility in hiring for specific projects or periods of
              time.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> What are the cost savings associated with using
            Teksett's IT staffing services?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              Hiring full-time IT professionals can be expensive, especially for
              small and medium-sized businesses. You'll only pay for the time
              and expertise that you need, without the added costs of benefits,
              taxes, and other expenses associated with hiring full-time
              employees.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> How long does to hire an IT professional through
            Teksett's IT staffing service?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              We can help your business reduce hiring time by providing a range
              of pre-screened and qualified candidates. The time it takes to
              hire an IT professional will depend on the specific requirements
              of your project and the availability of qualified candidates. Our
              team of experts will work with you to find the right candidate as
              quickly as possible based on our offshore and on-site models.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> What kind of IT professionals can Teksett's IT
            staffing services provide?
          </p>

          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              Teksett's IT staffing service works by providing businesses with
              access to a pool of top IT talent. We pre-screen and qualify
              candidates to ensure that they meet your requirements, and we
              provide flexibility in hiring for specific projects or periods of
              time.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Data Secience Solutions</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            {/* <section>
            <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
            <Link className="XS-sec2-in2-d1-sp1" to={`/services/serv3dss`} 
              onClick={handleScrollToTop}
            >
            Read More
            </Link>
            <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </section> */}

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/services/serv3dss`}
              onClick={handleScrollToTop}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv2sas;
