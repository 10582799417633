import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createContactus = async (payload) => {
  const response = await axios.post(
    `${BASE_URL}/akscel-demo-service/akscel_demo_model/create`,
    payload
  );
  return response;
};
