import React, { useState } from "react";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/i-exp-img9.png";
import Servexp2 from "../EXPimages/dm-h1-img1.png";
import { Link, useNavigate } from "react-router-dom";

const Dominsu10 = () => {
  const nav =useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <h1 className="XS-sec1-two-h1">INSURANCE</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Secure Your Insurance Business with Our Advanced IT Services
          </h1>
          <p className="XS-sec1-two-p1">
            Experience Reliable IT Support Tailored to Your Insurance Business
            Needs.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Customized solutions to meet your unique business needs
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Streamlined workflows for increased efficiency
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Expertise in insurance-specific software and systems
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Choose Teksett for Your Insurance IT Needs
          </h1>
          <p className="XS-sec1-two-p-p1">
            We understand the complex regulations and requirements that the
            industry demands, and we have the expertise to help you navigate
            these challenges. We take a personalized approach to every project,
            ensuring that your business needs are met with the highest level of
            service and support.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>


          {/* <button className="XS-sec1-two-btn1">
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button> */}

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p  onClick={() => setIsOpen1(!isOpen1)}   className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span> What is your experience
            working with insurance companies?
          </p>
          {isOpen1 && (
          <p className="XS-sec1-two-sp-pA">
            Our team has extensive experience working with insurance companies
            of all sizes, from small independent agencies to large multinational
            corporations.
          </p>
          )}
          <hr></hr>

          <p  onClick={() => setIsOpen2(!isOpen2)}  className="XS-sec1-two-sp-ps">
            <span>+</span> Can you help us with software and systems specific to
            the insurance industry?
          </p>
          {isOpen2 && (

          <p className="XS-sec1-two-sp-pA">
            Absolutely. Our team has expertise in a wide range of
            insurance-specific software and systems, and we can help you
            identify and implement the solutions that will work best for your
            business.
          </p>
          )}
          <hr></hr>

          <p  onClick={() => setIsOpen3(!isOpen3)}  className="XS-sec1-two-sp-ps">
            <span>+</span> How quickly can you respond to IT issues or
            emergencies?
          </p>
          {isOpen3 && (
          <p className="XS-sec1-two-sp-pA">
            We offer 24/7 support and monitoring, and our team is always on call
            to respond to any IT issues or emergencies as quickly as possible.
          </p>
          )}
          <hr></hr>

          <p  onClick={() => setIsOpen4(!isOpen4)}  className="XS-sec1-two-sp-ps">
            <span>+</span>How do you customize your solutions to meet our
            specific business needs?
          </p>
 {isOpen4 && (
          <p className="XS-sec1-two-sp-pA">
            We take a personalized approach to every project, working closely
            with our clients to understand their unique business needs and
            designing customized solutions to meet those needs.
          </p>
 )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Healthcare</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/domains/dom1`}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
            {/* <span className="XS-sec2-in2-d1-sp1">
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span> */}
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dominsu10;
