import React, { useEffect, useState } from "react";
import "./Insights.css";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "bootstrap/dist/css/bootstrap.css";
import { Link, NavLink } from "react-router-dom";

const Insights = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }
  return (
    <>
      <div className="I-sec1">
        <span>TEKSETT &lt; INSIGHTS</span>
        <h1>Careers and Insights</h1>
      </div>

      <div className="I-sec2">
        <div className="I-sec2-one">
          <h1>
            Focused expert teams
            <span>
              {" "}
              delivering<br></br>exception services since 1998
            </span>
          </h1>
        </div>

        {/* <div className="I-sec2-two">
          <img
            className="I-sec-two-img1"
            src="./PBimages/I-sec2-L-img1.png"
            alt=""
          />
          <img
            className="I-sec-two-img1
          "
            src="./PBimages/I-sec2-R-img2.png"
            alt=""
          />
        </div> */}
      </div>
      <ScrolableHistory />

      {/* <div className="I-sec-Ls">
        <div className="i-sec-ls-one">
          <span className="i-sec-ls-one-sp1 i-sec-ls-one-sp1-mb-v">1996</span>
          <h3 className="i-sec-ls-one-h3">1996</h3>
          <span className="i-sec-ls-one-sp2">Teksett was Founded</span>
          <hr></hr>
          <p>
            Teksett was founded in Omaha. It started as a small startup with a
            focus on developing software applications for businesses and
            providing best IT solutions.
          </p>
          <img
            className="i-sec-ls-one-sp2-img"
            src="./PBimages/I-sec-ls-img1.png"
            alt=""
          />
        </div>

        <div className="i-sec-ls-one i-sec-ls-one-mb-v">
          <span className="i-sec-ls-one-sp1">2001</span>
          <span className="i-sec-ls-one-sp2">
            Partnerships with top tech companies
          </span>
          <hr></hr>
          <p>
            The company expands its product line and starts offering web
            development services to cater to the growing demand for online
            solutions.
          </p>
          <img
            className="i-sec-ls-one-sp2-img img2ins"
            src="./PBimages/I-sec-ls-img1.png"
            alt=""
          />
        </div>
      </div> */}
{/* 
      <div className="I-sec3">
        <div className="I-sec3-one" id="insights-open-positions">
          <span>CAREERS</span>
        </div>

        <div className="I-sec3-two">
          <h1>
            View open <span>positions </span>
          </h1>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>Project Manager</h4>
              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <span className="i-sec3-span2"> 3-5 Years</span>
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>Senior IT Sales</h4>

              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <sapn className="i-sec3-span2"> 3-5 Years</sapn>{" "}
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>Marketing Associate</h4>

              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <sapn className="i-sec3-span2"> 3-5 Years</sapn>{" "}
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>Oracle ERP Associate</h4>
              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <sapn className="i-sec3-span2"> 3-5 Years</sapn>{" "}
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>SAP Utilities</h4>

              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <sapn className="i-sec3-span2"> 3-5 Years</sapn>{" "}
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div className="I-sec4">
        <div className="i-sec4-one">
          <span>WORK BENEFITS</span>
        </div>

        <div className="i-sec4-two" id="insights-work-benefits">
          <div className="i-sec4-two-h1">
            <h1>Why work with us</h1>
          </div>

          <div className="i-sec4-two-d1">
            <img
              className="i-sec4-imgs"
              src="./PBimages/I-sec5-img1.png"
              alt=""
            />
            <h4>Flexible working hours</h4>
            <p>
              We recognize that life's demands can be unpredictable, and rigid
              9-to-5 schedules may not always fit everyone's lifestyle. That's
              why we offer flexible working hours to our employees. We believe
              that when individuals can choose the hours that suit their
              productivity and personal commitments best, they can achieve
              better work-life integration.
            </p>
          </div>

          <div className="i-sec4-two-d1">
            <img
              className="i-sec4-imgs"
              src="./PBimages/I-sec5-img2.png"
              alt=""
            />
            <h4>Flexible Locations</h4>
            <p>
              At Teksett, we understand that different individuals have
              different preferences and needs when it comes to their work
              environment. That's why we offer the freedom to choose your work
              location. Whether you thrive in a traditional office setup, prefer
              a remote work arrangement, or seek a mix of both, we've got you
              covered.
            </p>
          </div>

          <div className="i-sec4-two-d1">
            <img
              className="i-sec4-imgs"
              src="./PBimages/I-sec5-img3.png"
              alt=""
            />
            <h4>Latest technologies</h4>
            <p>
              Innovation is at the core of what we do. We stay at the forefront
              of the industry by adopting and leveraging the latest
              technologies. As a member of our team, you'll have the opportunity
              to work with state-of-the-art tools, platforms, and frameworks. By
              being exposed to the latest advancements in the IT world, you can
              continually expand your skill set and stay ahead in a rapidly
              evolving tech landscape.
            </p>
          </div>

          <div className="i-sec4-two-d1">
            <img
              className="i-sec4-imgs"
              src="./PBimages/I-sec5-img44.png"
              alt=""
            />
            <h4>Health insurance</h4>
            <p>
              When it comes to your health, you deserve peace of mind. Our
              health insurance plans offer comprehensive coverage that takes
              care of your medical expenses, ensuring you receive the best
              possible care without worrying about financial burdens. From
              routine check-ups and preventive care to hospitalization and
              emergency services, our plans have you covered.
            </p>
          </div>
        </div>
      </div>

      <div className="I-sec5">
        <span>EMPLOYEE TESTIMONIALS</span>
      </div>

      <div className="i-sec5-one">
        <div className="i-sec5-one-d1">
          <img
            className="i-sec5-one-d1-img"
            src="./PBimages/I-sec6-img1.png"
            alt=""
          />
        </div>

        <div className="i-sec5-one-d2">
          <h1>Jacob S.</h1>
          <p>
            "I've had the pleasure of working with Teksett for over three years
            now, and it has been an incredible journey. The company's commitment
            to innovation and cutting-edge technologies has constantly pushed me
            to grow as a software engineer. The team's collaborative spirit
            fosters an environment of creativity and camaraderie, making every
            project a joy to work on.
          </p>
          <h6>(FE developer)</h6>
        </div>
      </div>

      <div className="H-sec11-one">
        <div className="h-sec11-one-d1">
          <img
            className="h-sec11-one-d1-img"
            src="./PBimages/h-sec11-one-img.png"
            alt=""
          />
        </div>

        <div className="h-sec11-one-d2">
          <h1 id="i-sec-l2-h1">
            Get the most professional <br className="ins-sec11-br1"></br>{" "}
            <span> technology services</span>
          </h1>
          <img src="./PBimages/bb-dots-img.png" alt="" />

          <p>
            Exceptional staffing solutions that drives business <br></br>success
            and help professional achieve their career goals.
          </p>

          <Link
            className="h-sec11-btn"
            to={`/contactus`}
            onClick={handleScrollToTop}
          >
            {" "}
            Get in Touch <span className="sp-div">|</span>
            <img className="h-sec1-A1" src="./PBimages/WhiteArrow.png" alt="" />
          </Link>
        </div>
      </div>
    </>
  );
};

function ScrolableHistory() {
  const ppic =
    "https://s3-alpha-sig.figma.com/img/4534/4435/f3faedb6dfd49d978d344bb23704956b?Expires=1691366400&Signature=ZrCW7dx5HUyeWCuTtlEf~vjQnjV8UlYoEIx-Ek~CpNOczOiFGYLeEAl3MJZDr3SD1hlGLyNSynH5zjEgGbH98mlcXvr1RjpXrLC8YGrOpvBqCHRNqmFw~WDxC1nDGNXu9XpC8FmT7a7khTZ6pJdjlwjm-SEYiQOhD83P~Gh94fhwik-XkbaQnqCYGSMGAs5u2o7e5dIJNJqDUCJomhBtazVxMYNKHT~ocrILzeU1qlH6dNaLJJc17h3oBR2C6W1okK0hmCw5p3rvstOsi71Uq5ElwFgC4gEUtHj-aRxL5ASoffHfeW8cI1RqzdRNQSRlCofGDsU8hqgyaoVDbAlTKw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4";
  const hhistory = [
    {
      year: 1998,
      title: "Teksett was Founded",
      des: "Teksett was founded in Omaha. It started as a small startup with a focus on developing software applications for businesses and providing best IT solutions.",
      pic: "./PBimages/I-sec-s-img1.png",
    },
    {
      year: 2001,
      title: "Expansion and Growth",
      // title: "Teksett was Founded",
      des: "Teksett expands its service offerings to encompass software development and IT consulting with companies like Tyson Foods, Honda Motors, Bell Atlantic (Formerly Verizon).",
      // pic: "https://s3-alpha-sig.figma.com/img/82dd/08d5/579b176a40c9dc87028ed6ee6dace485?Expires=1693180800&Signature=iOqQEOEgN2YDPlao7VJoWhmxPeN3AqsjGEoPdL2~vIdQEXkLIcxWJEnoBmUAdV8FCigq7O1xbZXrHvJTekbhRijSkypoMybjF6iM7EB~719CFBTB1IGMkyoqp8DLYzGv3zeq--4gj1jlfJ0hMbYRw0ttXbh-g~Aa2EtIOqnFo0iwmHNy0ywdQHQQpynAGcbXZofq5PPOOOlPT2caAosY37Ot~Poz4etQWVCa3CNDLI4RP8Xz5oBFmdgzbSASKHif7gqYz-U4DYqBtqAGd~VyCAR8qf~IFpyAqak1tLlCjgNaIXX0ug4x4EwlUPoorzoJU5NSNQjvUCnol9tTVfKq5A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      pic: "./PBimages/I-sec-s-img2.png",
    },
    {
      year: 2006,
      title: "Strategic Partnerships",
      des: "Teksett forms strategic partnerships with industry-leading tech companies like Google & Microsoft to enhance its service capabilities.",
      pic: "./PBimages/I-sec-s-img3.png",
      // pic: "https://s3-alpha-sig.figma.com/img/a5b8/653a/b9b24c751a4d8124c41c4bee2e5acf50?Expires=1693180800&Signature=S7knqKqipFmLd5C4un24KtbhXG1FhCOUw12rTftlzcIfLKfYWU3cPheCt7uBB88SG2uI7BubbCjpvgPC~t3Ha8eXC1gAlcLpSeQ3tf9Z4bcg58oo0DY31de3rHb0KobsiThL~F-ZSiLnMzMNMr6eRjIexKyfOE3aGllvsNcOhdZGpm7DZLwfAgdfrTH7JSgiZGlejduxZRR3GVtNzpYZgUmEHkyO2zDyO0ILU5yOAzhmACkUdkT2O9Oo01K-4N3otLY1Actl087utluwwA5JRWpVCqSNOG2kJwLuOI2yjiBiQIoPqdpWMOktQHnEw5JGVJMZZ4vtYjl-RnqYzwCpBQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
    },
    {
      year: 2011,
      title: "Industry Recognition",
      des: "Teksett receives its first industry award for excellence in software development and client satisfaction.",
      pic: "./PBimages/I-sec-s-img4.png",
      // pic: "https://s3-alpha-sig.figma.com/img/feb2/4669/c11206de14a4d11805d8690141065ef5?Expires=1693180800&Signature=HqmB~bFJc8IcnsnRs7lJVlHW7OSo2JkRd0CZgYDhl~k1Ny4qtuX9vB9ccXfzuv-12wkxmogBd3qakbU84syoej-i~DmZaofUuX5uvyjnuHhWNVoFXbvhty~8rZYe8UJVi487Ua6GAqxqz63Bj8o9EZSa8k7F0iVdjymeJhdSmDqwr-cIeH3PEETjB~PVVTWDuZVH35TiAxDO~tz8FWo~uJGoW4Xa2usCZbFOTGYkeVJtgjiJLDzNv~npLlWIvdrm~W6ysEVrdTjNqaaieQ-uHKpiWkJ87KUG-0PzK-9ssVwJ~eKj3j7eh5oCHua-Co1X--rCsa~jEkkBWBzeLBDaJQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
    },
    {
      year: 2016,
      title: "Cloud Expertise",
      des: "Teksett becomes a trusted provider of cloud solutions, helping businesses migrate and optimize their operations in the cloud.",
      pic: "./PBimages/I-sec-s-img5.png",
    },
    {
      year: 2021,
      title: "Industry-Specific Solutions",
      des: "Teksett develops industry-specific IT solutions, catering to the unique needs of various sectors, from healthcare to finance.",
      pic: "./PBimages/I-sec-s-img6.png",
    },
    {
      year: 2023,
      title: "Sustainable IT Practices",
      des: "Teksett embraces eco-friendly IT practices, promoting sustainability in technology solutions and operations",
      pic: "./PBimages/I-sec-s-img7.png",
    },
  ];

  const [slides, setSlides] = useState(2);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  // console.log(slides);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // Display fewer sponsors on mobile
        setSlides(1);
      } else {
        // Display all sponsors on larger screens
        setSlides(2);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="">
        <div className=" container d-flex justify-content-end my-3 py-3">
          <div
            className="btn  swiper-button-prev px-2 mx-2"
            onMouseEnter={() => setIsHovered1(true)}
            onMouseLeave={() => setIsHovered1(false)}
            style={{
              borderRadius: "50px",
              width: "60px",
              // float:'left',
              border: "1px solid #707070",
              backgroundColor: isHovered1 ? "#D9D9D9" : "transparent",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="47"
              viewBox="0 0 48 48"
              fill="none"
            >
              <g clip-path="url(#clip0_313_11100)">
                <path
                  d="M15.98 26L40 26L40 22L15.98 22L15.98 16L8 24L15.98 32L15.98 26Z"
                  fill="#323232"
                />
              </g>
              <defs>
                <clipPath id="clip0_313_11100">
                  <rect
                    width="48"
                    height="48"
                    fill="white"
                    transform="translate(48 48) rotate(180)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            className="btn swiper-button-next px-2 mx-2"
            onMouseEnter={() => setIsHovered2(true)}
            onMouseLeave={() => setIsHovered2(false)}
            style={{
              borderRadius: "50px",
              width: "60px",
              // float:'left',
              border: "1px solid #707070",
              backgroundColor: isHovered2 ? "#D9D9D9" : "transparent",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="47"
              viewBox="0 0 48 48"
              fill="none"
            >
              <g clip-path="url(#clip0_313_11099)">
                <path
                  d="M32.02 22H8V26H32.02V32L40 24L32.02 16V22Z"
                  fill="#323232"
                />
              </g>
              <defs>
                <clipPath id="clip0_313_11099">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <Swiper
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={slides} //2 slides
          className="px-lg "
          style={{ paddingLeft: "30%" }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
        >
          {hhistory.map(({ title, pic, des, year }, index) => (
            <SwiperSlide key={index}>
              <div className="col-11">
                <div className="p-1 ">
                  <div className="d-flex fs-4 ">
                    <div className="px-3 fs-3">{year}</div>
                    <div className=" fs-3">{title}</div>
                  </div>
                  <hr width={"130%"} />
                  <div className="row d-flex flex-column">
                    <div className="col-10 py-2 m-2">
                      <div
                        className="py-2 my-2"
                        style={{
                          color: "#161616",
                          // fontFamily: "Barlow",
                          fontSize: "20px",
                          // fontStyle: "normal",
                          fontWeight: "400",
                          // marginBottom:''
                          // width: "300px",
                        }}
                      >
                        {des}
                      </div>
                    </div>
                    <div className="col">
                      <img
                        className="img-fluid "
                        src={pic}
                        alt="none"
                        height={"60%"}
                        width={"400px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Insights;
