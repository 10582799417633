import React, { useState } from "react";
import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/d-x-A-img.png";
import Servexp2 from "../EXPimages/dm-ft3-img3.png";
import { Link, useNavigate } from "react-router-dom";

const Domag2 = () => {

  const nav =useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
 
  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <h1 className="XS-sec1-two-h1">AGRICULTURE</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Empowering the Future of Agriculture with Modern IT Solutions
          </h1>
          <p className="XS-sec1-two-p1">
            Teksett provides tailored IT solutions for the agriculture industry
            to enhance efficiency, productivity, and profitability
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Streamline Patient Management
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Data Security and Compliamce
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Healthcare operations optimization
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Transform Your Agribusiness with Our Smart IT Solutions.
          </h1>
          <p className="XS-sec1-two-p-p1">
            Our IT solutions for agriculture operations include IoT sensors,
            data analytics, and AI-powered tools, enabling precision farming and
            efficient agribusiness management. Our customized services help
            improve crop yield, reduce costs, and enhance overall productivity.
          </p>

          {/* <button className="XS-sec1-two-btn1">
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button> */}

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span> What type of IT solutions
            do you offer for agriculture operations?
          </p>

          {isOpen1 && (
          <p className="XS-sec1-two-sp-pA">
            We offer a range of smart solutions, including IoT sensors, data
            analytics, and AI-powered tools that can help improve crop yield,
            reduce costs, and enhance overall productivity.
          </p>
              )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)}  className="XS-sec1-two-sp-ps">
            <span>+</span> How can your IT solutions help me with precision
            farming?
          </p>
          {isOpen2 && (
          <p className="XS-sec1-two-sp-pA">
            Our technology enables you to monitor soil moisture, temperature,
            and other essential parameters in real-time, allowing you to make
            informed decisions based on accurate data.
          </p>
            )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)}  className="XS-sec1-two-sp-ps">
            <span>+</span> Can your IT solutions help me manage my agribusiness
            operations?
          </p>
          {isOpen3 && (
          <p className="XS-sec1-two-sp-pA">
            Yes, our solutions can help you manage your agribusiness operations
            efficiently. We offer supply chain management and inventory control
            services to help you streamline your processes, improve
            decision-making, and reduce operational costs.
          </p>
           )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)}  className="XS-sec1-two-sp-ps">
            <span>+</span>Will your IT solutions be customized to my specific
            needs?
          </p>
          {isOpen4 && (
          <p className="XS-sec1-two-sp-pA">
            Yes, our solutions are tailored to meet the specific needs of each
            client. We work closely with you to understand your requirements and
            develop a customized plan that aligns with your goals.
          </p>
 )}

          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Fintech</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/domains/domfin3`}
             
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
            {/* <span className="XS-sec2-in2-d1-sp1">
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span> */}
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Domag2;
