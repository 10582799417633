import React, { useEffect, useState } from "react";
import "./Aboutus.css";
import { useLocation } from "react-router-dom";

const Aboutus = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const { state } = useLocation();

  useEffect(() => {
    if (state?.ourStory) {
      setIsOpen4(true);
    } else if (state?.coreValues) {
      setIsOpen1(true);
      setIsOpen2(true);
      setIsOpen3(true);
    }
  }, [state]);

  return (
    <>
      <div className="A-sec1">
        <span>TEKSETT &lt; ABOUT US</span>
        <h1>
          About: We're here to <br className="a-sec1h1-t"></br>help you{" "}
          <br></br>succeed!
        </h1>
      </div>

      <div className="A-sec2">
        <img className="a-sec2-img" src="./PBimages/A-sec2-img1.png" alt="" />
        <img
          className="a-sec2-mbv-img"
          src="./PBimages/A-sec2-mbv-img.png"
          alt=""
        />
      </div>

      <div className="A-sec3-c" id="aboutus-who-we-are">
        <div className="A-sec3-one">
          <h1>
            Who we <span>are</span>
          </h1>
          <hr></hr>
        </div>

        <div className="A-sec3-two">
          <p className="a-sec3-c-p1">
            Formerly known as SmartStream Inc, Teksett has been a leading IT
            service provider for a diverse range of clients since 1998. We're a
            team of tech-savvy professionals who are passionate about delivering
            the best possible outcomes for our clients. We take pride in our
            ability to understand your unique business needs and provide
            customized IT solutions that help you achieve your goals.
          </p>

          <p className="a-sec3-c-p1">
            From our cutting-edge solutions to our staff augmentation solutions,
            our services are designed to help your business succeed. We're
            committed to going above and beyond to ensure your satisfaction, and
            our team is always here to provide the support and guidance you
            need.
          </p>

          <p className="a-sec3-c-p1">
            At Teksett, we're not just a company - we're a community. Our team
            is made up of real people who care about technology and love helping
            others succeed. We're here to help you harness the power of
            technology and achieve your business goals, no matter what
            challenges you may face along the way.
          </p>

          <h6 id="aboutus-corevalues">CORE VALUES</h6>
          <h4 onClick={() => setIsOpen1(!isOpen1)}>
            <span>+</span> Our Vision
          </h4>
          {isOpen1 && (
            <p id="a-sec3-p1" className="A-sec3-h-p1">
              Our vision is to be the leading provider of exceptional IT
              solutions, recognized for our commitment to client success and
              innovation in the tech industry.
            </p>
          )}
          <hr />
          <h4 onClick={() => setIsOpen2(!isOpen2)}>
            {" "}
            <span>+</span> Our Mission
          </h4>
          {isOpen2 && (
            <p className="A-sec3-h-p1">
              Our mission is to deliver top-notch IT solutions to businesses
              through innovation, customized technology solutions, training,
              staffing,and consulting services that connect them with the best
              IT talent
            </p>
          )}
          <hr></hr>
          <h4 onClick={() => setIsOpen3(!isOpen3)}>
            {" "}
            <span>+</span> Our Values
          </h4>
          {isOpen3 && (
            <p className="A-sec3-h-p1">
              At Teksett, we are guided by a set of core values that inform
              everything we do.<br></br> We are committed to:<br></br>
              Integrity: We always act with honesty, transparency, and respect.
              <br></br>
              Innovation: We embrace new ideas and technologies to stay ahead of
              the curve.<br></br>
              Collaboration: We work closely with our clients and candidates to
              find the best solutions.<br></br>
              Excellence: We strive to exceed expectations in everything we do
              by providing exceptional service to our clients
            </p>
          )}
          <hr></hr>
          <h4 id="aboutus-ourStory" onClick={() => setIsOpen4(!isOpen4)}>
            {" "}
            <span>+</span> Our Story
          </h4>
          {isOpen4 && (
            <p className="A-sec3-h-p1">
              Our journey began over two decades ago with a shared love of all
              things tech. We saw a need for top-notch IT solutions for
              businesses looking to stay ahead of the game. Our mission was
              clear - to create an IT company that not only provides exceptional
              talent but also puts the needs of our clients first. Today, we're
              proud to say that we have helped countless businesses find the IT
              solutions they need to succeed. From IT solutions to software
              development, our team is dedicated to providing excellent IT
              solutions that our clients deserve.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      {/* <h6>CORE VALUES</h6>

          <h4>
            {" "}
            <span>+</span> Our Vision
          </h4>
          <p id="a-sec3-p1" className="A-sec3-h-p1">
            Our vision is to be the leading provider of exceptional IT
            solutions, recognized for our commitment to client success and
            innovation in the tech industry.
          </p>
          <hr></hr>
          <h4>
            {" "}
            <span>+</span> Our Mission
          </h4>
          <p className="A-sec3-h-p1">
            Our mission is to deliver top-notch IT solutions to businesses
            through innovation, customized technology solutions, training,
            staffing,and consulting services that connect them with the best IT
            talent
          </p>
          <hr></hr>
          <h4>
            {" "}
            <span>+</span> Our Values
          </h4>
          <p className="A-sec3-h-p1">
            At Teksett, we are guided by a set of core values that inform
            everything we do.<br></br> We are committed to:<br></br>
            Integrity: We always act with honesty, transparency, and respect.
            <br></br>
            Innovation: We embrace new ideas and technologies to stay ahead of
            the curve.<br></br>
            Collaboration: We work closely with our clients and candidates to
            find the best solutions.<br></br>
            Excellence: We strive to exceed expectations in everything we do by
            providing exceptional service to our clients
          </p>
          <hr></hr>
          <h4>
            {" "}
            <span>+</span> Our Story
          </h4>
          <p className="A-sec3-h-p1">
            Our journey began over two decades ago with a shared love of all
            things tech. We saw a need for top-notch IT solutions for businesses
            looking to stay ahead of the game. Our mission was clear - to create
            an IT company that not only provides exceptional talent but also
            puts the needs of our clients first. Today, we're proud to say that
            we have helped countless businesses find the IT solutions they need
            to succeed. From IT solutions to software development, our team is
            dedicated to providing excellent IT solutions that our clients
            deserve.
          </p>
          <hr></hr>
        </div>
      </div> */}

      <div className="main_circle">
        <div className="circle zoom-in-effect">
          <div className="text">Enthusiasm</div>
          <div className="number">1</div>
        </div>
        <div className="circle zoom-in-effect">
          <div className="text">transparency</div>
          <div className="number">2</div>
        </div>
        <div className="circle zoom-in-effect">
          <div className="text">competence</div>
          <div className="number">3</div>
        </div>
        <div className="circle zoom-in-effect">
          <div className="text4">stability</div>
          <div className="number">4</div>
        </div>
      </div>

      <div className="A-se5">
        <h1>
          We are happy to work with<br></br>
          <span> largest global brands</span>
        </h1>
      </div>

      <div className="A-sec6">


      <div className="A-sec6-one">

          <div className="">
            <img className="A-sec6-img1" src="./PBimages/f2-c-img1.png" />
          </div>

          <div className="">
            <img className="A-sec6-img1" src="./PBimages/f2-c-img2.png" />
          </div>
          <div className="">
            <img className="A-sec6-img1" src="./PBimages/f2-c-img3.png" />
          </div>
          <div className="">
            <img className="A-sec6-img1" src="./PBimages/f2-c-img4.png" />
          </div>
          <div className="">
            <img className="A-sec6-img1" src="./PBimages/f2-c-img5.png" />
          </div>
          <div className="">
            <img className="A-sec6-img1" src="./PBimages/f2-c-img6.png" />
          </div>
          <div className="">
            <img className="A-sec6-img1" src="./PBimages/f2-c-img7.png" />
          </div>
          <div className="">
            <img className="A-sec6-img1" src="./PBimages/f2-c-img8.png" />
          </div>
          {/* <div className="">
            <img className="" src="./PBimages/f2-c-img9.png" />
          </div>
          <div className="">
            <img className="" src="./PBimages/f2-c-img10.png" />
          </div> */}
        </div>

        {/* <div className="A-sec6-one">
          <img
            className="A-sec6-img1"
            src="./PBimages/A-clients-imgs.png"
            alt=""
          />
        </div> */}

        <div className="A-sec6-two">
          <div className="A-sec6-two-img-d1">
            <img
              className="A-sec6-two-img1"
              src="./PBimages/A-sec5-img2.png"
              alt=""
            />

            <img
              className="A-sec6-two-img1-tbv"
              src="./PBimages/A-sec6-tbv-img.png"
              alt=""
            />
          </div>

          <div className="A-sec6-d2-content">
            <h1>Robert Steward</h1>
            <p>
              Teksett's cutting-edge technology has streamlined our financial
              processes, making them more efficient and secure. The platform
              they provided us with is user-friendly, intuitive, and has
              significantly improved our productivity. It has allowed us to
              focus more on our core business activities while leaving the
              complexities of financial management to their capable hands.
            </p>
            <h3>CFO, Fintec Client</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
