import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Ser1.css";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img9.png";
import Servexp2 from "../EXPimages/service-de11-img11.png";

const Serv10pd = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  const nav = useNavigate();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">PROCDUCT DEVELOPMENT</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Bring your product ideas to life with Teksett
          </h1>
          <p className="XS-sec1-two-p1">
            From ideation to launch, we'll be with you every step of the way.
            Our product development process is designed to help you innovate and
            stay ahead of the competition.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Experienced developers and designers
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Quick turnaround times and efficient project management
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Continuous communication and transparency
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why choose Teksett for your product developement
          </h1>
          <p className="XS-sec1-two-p-p1">
            At Teksett, we understand that your product is an extension of your
            brand. That's why we take a collaborative approach to product
            development, working closely with you to ensure that your vision is
            brought to life. With a focus on quality, efficiency, and
            communication, we strive to exceed your expectations and provide you
            with a product that drives your business growth.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
            onClick={handleScrollToTop}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>How long does it take to
            develop a custom product?
          </p>
          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              The timeline for developing a custom product depends on the
              complexity of the project and your specific requirements. Our team
              will work closely with you to understand your needs and provide a
              detailed timeline for the project.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> How do you ensure the quality of the product?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              Our team follows a rigorous quality assurance process to ensure
              that the product meets your specifications and is free from any
              bugs or errors. We also conduct thorough testing to ensure that
              the product functions smoothly.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> Can you provide ongoing support after the product is
            launched?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              Yes, we provide ongoing support and maintenance services to ensure
              that the product continues to function smoothly and meets your
              evolving business needs.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> Do you provide any guarantees for your product
            development services?
          </p>
          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              Yes, we stand behind the quality of our work and provide a
              satisfaction guarantee for our product development services.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Data Engineering</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>
            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/services/serv11de`}
              onClick={handleScrollToTop}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv10pd;
