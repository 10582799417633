import React, { useState } from "react";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/i-exp-img8.png";
import Servexp2 from "../EXPimages/dm-insu10-img10.png";
import { Link, useNavigate } from "react-router-dom";

const Domtele9 = () => {


  const nav =useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <h1 className="XS-sec1-two-h1">TELECOMMUNICATION</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Powering Telecommunications with Next-Gen IT Solutions
          </h1>
          <p className="XS-sec1-two-p1">
            In a world where communication is key, your business needs IT
            solutions that can keep up with the speed of technology. Our team of
            experts at Teksett has a deep understanding of the latest trends and
            technologies, and we are committed to helping your business succeed.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Network infrastructure Management
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Telecom-Specific IT Consultancy
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Software Development for Telecommunications
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Teksett - Your Partner for Comprehensive IT Solutions in
            Telecommunications
          </h1>
          <p className="XS-sec1-two-p-p1">
            At Teksett, we understand the importance of robust and reliable
            network infrastructure for telecommunications businesses. That's why
            we offer expert network infrastructure management services, from
            designing new infrastructure to upgrading existing ones, to improve
            your network performance and overall efficiency.
          </p>


          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

        

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p  onClick={() => setIsOpen1(!isOpen1)}  className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span> What kind of network
            infrastructure services do you offer?
          </p>

          {isOpen1 && (
          <p className="XS-sec1-two-sp-pA">
            We offer a range of network infrastructure management services,
            including upgrading and designing new infrastructure, to help
            improve network performance and increase overall efficiency.
          </p>
          )}
          <hr></hr>

          <p  onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> Can you develop custom software solutions for our
            telecommunications business?
          </p>
          {isOpen2 && (
          <p className="XS-sec1-two-sp-pA">
            Yes, we specialize in developing custom software solutions that are
            specifically tailored to meet the unique needs of the
            telecommunications industry. Our team of experienced developers can
            build cutting-edge software solutions that can help your business
            stay ahead of the competition.
          </p>
          )}
          <hr></hr>

          <p  onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> How can your IT consultancy services help my
            telecommunications business?
          </p>
          {isOpen3 && (
          <p className="XS-sec1-two-sp-pA">
            Our team of expert IT consultants offers specialized consultancy
            services to help streamline your IT processes and optimize your
            workflows. We work closely with you to identify your unique needs
            and develop customized solutions that help you achieve your goals.
          </p>
          )}
          <hr></hr>

          <p  onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> Do you provide ongoing support and maintenance for
            your IT solutions?
          </p>
          {isOpen4 && (
          <p className="XS-sec1-two-sp-pA">
            Yes, we offer ongoing support and maintenance services to ensure
            your IT solutions continue to operate smoothly and effectively. We
            also provide regular updates and upgrades to keep your systems
            up-to-date with the latest technologies and advancements in the
            industry.
          </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Insurance</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/domains/dominsu10`}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
            {/* <span className="XS-sec2-in2-d1-sp1">
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span> */}
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Domtele9;
