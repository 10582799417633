import React from 'react'
import "./Products.css"

const Products = () => {

    function handleScrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "instant",
        });
      }

  return (
    <>

    <div className='P-sec1'>
    <span>TEKSETT &lt; PRODUCTS </span>
        <h1> Products </h1>
    </div>


    <div className="S-sec2">

        <div className="p-sec2-d1">
          <img src="./PBimages/P-img1.png" alt="" />
        </div>

        <div className="p-sec2-d2">
          <div className="p-sec2-d2-in1">
            <h1>Product Dispatch and Tracking</h1>
            <p>
            Product Dispatch and Tracking involves software being designed with the manufacturing & e-commerce (insurance, retail, healthcare and various other industries) in mind. This will help the user to keep track of each individual consignment from the time of dispatch until delivary at the final destination.
            </p>

            <p>Many times the products are stored in the intermediate storage locations so as to reduce the total time taken for the products to reach the final destination. The software also has features to track the locations of products stored at intermediate locations. Thus, the management is assisted in locating the product nearest to the final destination.</p>
          
           
          </div>
        </div>
      </div>


      
    <div className="S-sec2">
        <div className="p-sec2-d1">
          <img src="./PBimages/P-img2.png" alt="" />
        </div>

        <div className="p-sec2-d2">
          <div className="p-sec2-d2-in1">
            <h1 id="IT Solutions delivery"> Plant Data and Reporting Systems </h1>
            <p>
            Plant Data and Reporting system's objective is to provide optinal job rotation in order to minimize risk of injury deo to repetitive motion.
            </p>

            <p> We are calling this product as "Right Person on the Right Job". </p>

            <p> For the team leader, this product saves time by producing an optimal assignment within a few minutes.For the associate, the system assigns only trained associate to jobs. The system also takes account a person's medical restrictions while making assignments. The data in the system is used to generate reports to identify the associates that are not getting good job rotation. </p>
          
           
          </div>
        </div>
      </div>

  

    </>
  )
}

export default Products