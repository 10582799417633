import React from 'react'
import "./Blogs.css"
import { Link } from 'react-router-dom';

const Blogs = () => {

    function handleScrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "instant",
        });
      }

  return (

    <>

    <div className='P-sec1'>
    <span>TEKSETT &lt; BLOGS </span>
        <h1 id="blogspage"> Blogs </h1>
    </div>


    <div className="b-h-sec-2">

        <div className="bg-in-sec1" >
          <img
            className="bg-sec2-img1"
            src="./PBimages/H-sec12-two-img1.png"
            alt="Public clouds image"
          />
          <h5 id="bg-cd-h1"  >
            Public clouds vs. own <br></br> servers: do the math!
          </h5>
         
          <section id="h-sec12-sp-p">
            <img
              className="h-sec12-circle"
              src="./PBimages/circle-img.png"
              alt=""
            />
            <Link
              className="h-sec12-link-L1"
              id="h-sec12-lin"
              to={`/blogs/blogexp4`}
            >
              Read More
            </Link>
            <img className="h-sec12-A" src="./PBimages/BlackArrow.png" alt="" />
          </section>
        </div>

        <div className="bg-in-sec1">
          <img
            className="bg-sec2-img1"
            src="./PBimages/h-sec12-two-img2.png"
            alt="Cloud transformation images"
          />
          <h5 className="bg-cd-c2" >
            Cloud transformation, change management and exit strategy- A
            holistic approach.
          </h5>
          <section id="h-sec12-sp-p">
            <Link id="h-sec12-lin" to={`/blogs/blogexp1`}>
              <img
                className="h-sec12-circle"
                src="./PBimages/circle-img.png"
                alt=""
              />
              Read More
              <img
                className="h-sec12-A"
                src="./PBimages/BlackArrow.png"
                alt=""
              />
            </Link>
          </section>
        </div>

        <div className="bg-in-sec1">
          <img
            className="bg-sec2-img1"
            src="./PBimages/h-sec12-two-img3.png"
            alt="Securely integrating"
          />
          <h5 className="bg-cd-3">
            Securely integrating legacy application into the cloud with Azure
            AppProxy
          </h5>

          <section id="h-sec12-sp-p">
            <Link id="h-sec12-lin" to={`/blogs/blogexp2`}>
              <img
                className="h-sec12-circle"
                src="./PBimages/circle-img.png"
                alt=""
              />
              Read More
              <img
                className="h-sec12-A"
                src="./PBimages/BlackArrow.png"
                alt=""
              />
            </Link>
          </section>
        </div>

        <div className="bg-in-sec1 h-sec12-br">
          <img
            className="bg-sec2-img1"
            src="./PBimages/h-sec12-two-img4.png"
            alt="What is IT architecture"
          />
          <h5 id="bg-cd-4">
            What is IT architecture and why does it matter?
          </h5>

          <section id="h-sec12-sp-p" className="h-sec12-sp-p-rm4">

            <Link id="h-sec12-lin" to={`/blogs/blogexp3`}>
              <img
                className="h-sec12-circle"
                src="./PBimages/circle-img.png"
                alt=""
              />
              Read More
              <img
                className="h-sec12-A"
                src="./PBimages/BlackArrow.png"
                alt=""
              />
            </Link>
          </section>
        </div>
      </div>

    {/* <div className="S-sec2">

<div className="p-sec2-d1">
  <img src="./PBimages/b-sec1-img4.png" alt="" />
</div>

<div className="p-sec2-d2">
    
  <div className="p-sec2-d2-in1">

  <h1> Public clouds vs. own servers: do the math!</h1>
    <p>
    In today's digital era, businesses are constantly exploring ways to optimize their IT infrastructure to stay competitive and meet evolving customer demands. When it comes to hosting applications and data, the two most popular options are leveraging public cloud services or maintaining in-house servers. Choosing the right approach is critical, as it directly impacts performance, scalability, cost, and overall business efficiency. In this blog, we will conduct a comprehensive cost-benefit analysis of public cloud services versus owning and managing your servers. So, let's do the math and find out which option suits your business best!
    </p>

    <Link
              className="B-sec2-btn"
              to={`/blogs/blogexp4`}
              onClick={handleScrollToTop}
            >
                Read More
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
  

  </div>
</div>
</div> */}


    {/* <div className="S-sec2">

<div className="p-sec2-d1">
  <img src="./PBimages/b-sec1-img1.png" alt="" />
</div>

<div className="p-sec2-d2">

  <div className="p-sec2-d2-in1">

    <h1> Cloud transformation, change management and exit strategy- A
            holistic approach.</h1>

   
    <p>
    In today's rapidly evolving business landscape, cloud transformation has emerged as a key enabler for organizations to achieve greater agility, scalability, and cost-efficiency. However, the journey to the cloud involves much more than just adopting new technology. It requires a holistic approach that encompasses change management and a well-defined exit strategy. In this blog, we will explore the essential components of a successful cloud transformation and how to navigate the challenges that come with it.
    </p>

  

  </div>
</div>
</div> */}

{/* 
<div className="S-sec2">

<div className="p-sec2-d1">
  <img src="./PBimages/b-sec1-img3.png" alt="" />
</div>

<div className="p-sec2-d2">
    
  <div className="p-sec2-d2-in1">

<h1> Securely integrating legacy application into the cloud with Azure
            AppProxy</h1>
   
    <p>
    In today's digital age, businesses and organizations rely heavily on Information Technology (IT) to streamline operations, enhance productivity, and stay competitive. Behind the scenes of every successful IT infrastructure lies a crucial framework known as IT architecture. In this blog, we will delve into what IT architecture is, its significance, and why it matters for businesses seeking to harness the full potential of technology.
    </p>

  </div>
</div>
</div>


<div className="S-sec2">

<div className="p-sec2-d1">
  <img src="./PBimages/b-sec1-img2.png" alt="" />
</div>

<div className="p-sec2-d2">
    
  <div className="p-sec2-d2-in1">

    <h1> What is IT architecture and why does it matter?</h1>

    <p>
  As businesses continue to embrace the cloud for its scalability and cost-efficiency, integrating legacy applications into this new environment can be a challenging task. Many organizations rely on legacy systems to maintain critical business processes and data. However, with Microsoft's Azure AppProxy, migrating these applications to the cloud becomes a secure and seamless process. In this blog, we will explore how Azure AppProxy can securely integrate legacy applications into the cloud, ensuring a smooth transition without compromising on security.

  </p>

  </div>
</div>
</div> */}

    </>
  )
}

export default Blogs