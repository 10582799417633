import React, { useState } from "react";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/i-exp-img3.png";
import Servexp2 from "../EXPimages/dm-gm5-img5.png";
import { Link, useNavigate } from "react-router-dom";

const Domrm4 = () => {
  const nav = useNavigate();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <h1 className="XS-sec1-two-h1">RETAIL AND MANUFACTURING</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Your Retail and Manufacturing Business Deserves Innovative IT
          </h1>
          <p className="XS-sec1-two-p1">
            Streamline your operations and improve your bottom line with
            Teksett's tailored IT services for the retail and manufacturing
            industry. Our tailored approach ensures that we understand your
            unique needs and deliver the solutions that meet them.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Optimized Supply Chain Management
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Enhanced Customer Experience
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Robust Data Analytics
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Streamline your retail and manufacturing operations with Teksett
          </h1>
          <p className="XS-sec1-two-p-p1">
            Our software solutions offer optimized supply chain management,
            enhanced customer experience, and robust data analytics tools. Our
            supply chain management solutions are customizable, offer end-to-end
            supply chain visibility, and seamlessly integrate with your existing
            systems.
          </p>

          <Link className="XS-sec1-two-btn1" to={`/contactus`}>
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          {/* <button className="XS-sec1-two-btn1">
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button> */}

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>Can your solutions be
            customized to fit my business needs?
          </p>
          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              Yes, we understand that every business is unique, and our supply
              chain management solutions can be tailored to meet your specific
              requirements.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> How do your solutions ensure supply chain visibility?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              Our solutions offer end-to-end supply chain visibility, allowing
              you to track your inventory in real-time, monitor order
              fulfillment, and optimize shipping and delivery.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span>Can your solutions integrate with my existing ERP
            system?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              Yes, our supply chain management solutions are designed to
              seamlessly integrate with your existing ERP system, enabling you
              to manage your entire supply chain from a single platform.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span>What kind of data insights can I get from your
            analytics tools?
          </p>
          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              Our analytics tools provide real-time insights into your inventory
              levels, order fulfillment, sales trends, and customer behavior,
              enabling you to make informed business decisions.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>GAMING AND ENTERTAINMENT</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link className="XS-sec2-in2-d1-sp1" to={`/domains/domgm5`}>
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>

            {/* <span className="XS-sec2-in2-d1-sp1">
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span> */}
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Domrm4;
