import React, { useState } from "react";
import "./Ser1.css";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img2.png";
import Servexp2 from "../EXPimages/S-sec3-BA-img4.png";
import { Link, useNavigate } from "react-router-dom";

const Serv3dss = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const nav=useNavigate()
  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">Data Science Solutions</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />
          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Build a World-class team with our Top IT experts
          </h1>
          <p className="XS-sec1-two-p1">
            Harness the power of your data with Teksett's expertise in machine
            learning algorithms and advanced statistical tools. Our tailored
            solutions drive actionable insights and empower you to make informed
            decisions for exponential growth.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Precise predictive modeling capabilities
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Robust data analysis techniques
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Reliable and accurate results
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why Choose Teksett for your Data Science Solutions?
          </h1>
          <p className="XS-sec1-two-p-p1">
            With Teksett, you can benefit from our unparalleled expertise in
            implementing a diverse range of machine learning algorithms, such as
            Decision Trees, Regression, Black Box, 5-way split, and Random
            Forest. Our team of data scientists possesses the knowledge and
            experience to extract valuable insights from your data, empowering
            you to make informed decisions that drive business growth.
          </p>

          <button className="XS-sec1-two-btn1" onClick={() => nav("/contactus")} >
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>What machine learning
            algorithms are utilized in your data science solutions?
          </p>
          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              Our data science solutions employ a variety of machine learning
              algorithms, including Decision Trees, Regression, Random Forest,
              and other advanced techniques, to uncover patterns and make
              accurate predictions.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> Which statistical tools do you use in your data
            science services?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              We utilize industry-standard statistical tools such as SAS, R, and
              Python, along with libraries like Pandas, Matplotlib, and Sci-kit,
              to perform robust statistical analysis and develop accurate
              models.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> How can data science solutions benefit my business?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              Data science solutions enable businesses to extract valuable
              insights from complex data, optimize decision-making, identify
              trends, and drive growth. By leveraging advanced algorithms and
              techniques, we help uncover hidden patterns and opportunities for
              success.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> What sets Teksett apart in providing data science
            solutions?
          </p>

          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              At Teksett, we have a team of experts specializing in AI, ML, data
              engineering, and data science. We offer customized solutions using
              cutting-edge technologies and tools like Hadoop, Amazon S3,
              Snowflake, and ETL tools, ensuring exceptional outcomes for
              businesses across industries.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Business Analytics</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link className="XS-sec2-in2-d1-sp1" to={`/services/serv4ba`}>
              <span className="XS-sec2-in2-d1-sp1">
                <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
                Read More
                <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
              </span>
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv3dss;
