import React, { useState } from "react";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/i-exp-img66.png";
import Servexp2 from "../EXPimages/dm-gov7-img7.png";
import { Link, useNavigate } from "react-router-dom";

const Domgov7 = () => {

  const nav =useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <h1 className="XS-sec1-two-h1">GOVERNMENT</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Empowering the Government with Robust IT Solutions
          </h1>
          <p className="XS-sec1-two-p1">
            We provide reliable and secure IT services that help government
            agencies streamline their operations and improve their service
            delivery.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Deep understanding of government operations and regulations
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Robust and secure IT infrastructure
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Expertise in implementing
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            IT Solutions Tailored For Your Needs
          </h1>
          <p className="XS-sec1-two-p-p1">
            With our expertise in IT, we are committed to delivering solutions
            that help government organizations streamline operations, improve
            efficiency, and enhance security. Trusted IT Services for Government
            Agencies. We understand that the government sector requires
            specialized IT solutions that are tailored to meet their unique
            needs. That's why we offer a range of services that are specifically
            designed to meet the demands of government organizations.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
      
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>


          {/* <button className="XS-sec1-two-btn1">
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button> */}

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span> What types of IT services
            do youn provide to the governement sector?
          </p>
          {isOpen1 && (
          <p className="XS-sec1-two-sp-pA">
            We provide a range of IT services including software development,
            cybersecurity, cloud computing, business analytics, and IT
            consulting.
          </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> How do you ensure the security of the solutions you
            provide to government agencies?
          </p>
          {isOpen2 && (
          <p className="XS-sec1-two-sp-pA">
            We follow industry best practices and comply with government
            regulations to ensure the security of our solutions. We conduct
            regular security audits and provide training to our staff to ensure
            that they are up-to-date with the latest security protocols.
          </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> How do you ensure that your solutions meet the unique
            needs of government agencies?
          </p>
          {isOpen3 && (
          <p className="XS-sec1-two-sp-pA">
            We work closely with our clients to understand their specific needs
            and requirements. We then develop tailored solutions that meet their
            needs and exceed their expectations.
          </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> How can I get started with your IT services for the
            government sector?
          </p>
          {isOpen4 && (
          <p className="XS-sec1-two-sp-pA">
            To get started, simply contact us via phone or email and one of our
            representatives will be happy to assist you. We will work with you
            to understand your specific needs and provide you with a customized
            solution that meets your requirements.
          </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Utilities</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>
            
            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/domains/domult8`}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
            {/* <span className="XS-sec2-in2-d1-sp1">
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span> */}
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Domgov7;
