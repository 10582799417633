import React, { useState } from "react";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/i-exp-img5.png";
import Servexp2 from "../EXPimages/dm-gov7-img7.png";
import { Link, useNavigate } from "react-router-dom";

const Dombank6 = () => {
  const nav =useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <h1 className="XS-sec1-two-h1">BANKING</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Innovation and Security for the Banking Industry
          </h1>
          <p className="XS-sec1-two-p1">
            Our IT services are designed to help banks enhance their operational
            efficiency, streamline processes, and reduce costs while maintaining
            the highest levels of security.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Expert-led team and professionals
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Reliable and scalable banking solutions
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Ongoing support and maintenance
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Reliable IT solutions for the Banking Industry
          </h1>
          <p className="XS-sec1-two-p-p1">
            We offer customized IT solutions that are tailored to the specific
            needs of each bank, including core banking applications, transaction
            processing, data analytics, and digital banking platforms, ensuring
            that they get the most out of their technology investments. We do
            all these while also complying with industry regulations and
            standards.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>


          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)}  className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span> What types of IT services
            do you offer for banks?
          </p>

          {isOpen1 && (
          <p className="XS-sec1-two-sp-pA">
            We offer a wide range of IT services for banks, including network
            infrastructure management, data security, cloud computing, software
            development, and IT consulting, among others.
          </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)}   className="XS-sec1-two-sp-ps">
            <span>+</span> How do you ensure the security of our data?
          </p>

  {isOpen2 && (
          <p className="XS-sec1-two-sp-pA">
            We utilize advanced security technologies and protocols, including
            firewalls, intrusion detection and prevention systems, encryption,
            and multi-factor authentication, to protect your data from
            unauthorized access, theft or loss.
          </p>
  )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)}    className="XS-sec1-two-sp-ps">
            <span>+</span> How do you ensure the reliability and availability of
            our IT infrastructure?
          </p>
          {isOpen3 && (
          <p className="XS-sec1-two-sp-pA">
            We utilize proactive monitoring and management tools to detect and
            resolve potential issues before they impact your business
            operations. We also offer backup and disaster recovery solutions to
            ensure that your data is always available, even in the event of a
            system failure or natural disaster.
          </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)}     className="XS-sec1-two-sp-ps">
            <span>+</span>What is your approach to IT consulting for banks?
          </p>
          {isOpen4 && (
          <p className="XS-sec1-two-sp-pA">
            Our approach to IT consulting involves working closely with you to
            understand your business objectives, IT infrastructure, and
            technology requirements, and then developing a customized roadmap
            that aligns with your goals and budget.
          </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Government</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/domains/domgov7`}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>

            {/* <span className="XS-sec2-in2-d1-sp1">
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span> */}
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dombank6;
