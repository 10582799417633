import React, { useState } from "react";
import "./Contactus.css";
import { useNavigate } from "react-router-dom";
import { createContactus } from "../api/requestDemo";

const initialStates = {
  portal_name: "Teksett",
  requested_demo_for_product_name: "Teksett portal",
  first_name: "",
  last_name: "",
  orgnization_email: "",
  orgnization_name: "Teksett",
  message: "",
  country: "",
  onSite: false,
};

const ApplyNow = () => {
  const nav = useNavigate();
  const [formData, setFormData] = useState(initialStates);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { onSite, ...rest } = formData;
    const bodyData = { ...rest };
    try {
      const res = await createContactus(bodyData);
      setFormData(initialStates);
      alert(res.data.message);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div className="C-sec">
        <div className="c-sec-1">
          <img className="ct-img" src="./PBimages/applynow.png" alt="" />
        </div>

        <div className="c-sec-2">
          <span className="c-sec-2-sp1">
            {" "}
            <span className="XS-sec1-two-sp1" onClick={() => nav("/insights")}>
              <span className="" style={{ padding: "2px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="9"
                  viewBox="0 0 7 12"
                  fill="none"
                >
                  <path
                    d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                    fill="black"
                  />
                </svg>{" "}
              </span>
              Back to Careers
            </span>
          </span>
          <h1 className="c-sec-2-h1">
            Apply <span>Now</span>{" "}
          </h1>

          <section className="c-sec9">
            <form onSubmit={handleSubmit} className="c-sec3-form">
              <h6 className="c-sec3-form-h6">
                Apply to this job by Filling this Form
              </h6>
              <input
                type="text"
                className="c-sec3-form-p1"
                name="first_name"
                placeholder="First Name"
                onChange={handleChange}
                required
              />
              <input
                type="text"
                className="c-sec3-form-p1"
                placeholder="Last Name"
                name="last_name"
                onChange={handleChange}
                required
              />
              <input
                type="email"
                className="c-sec3-form-p1"
                placeholder="Email ID"
                name="orgnization_email"
                onChange={handleChange}
                required
              />
              <input
                type="text"
                className="c-sec3-form-p1"
                placeholder="Company Name"
                name="orgnization_name"
                onChange={handleChange}
                required
              />
              <input
                type="text"
                className="c-sec3-form-p1"
                placeholder="Your Location"
                name="country"
                onChange={handleChange}
                required
              />
              <select
                className="c-sec3-form-p1"
                onChange={handleChange}
                name="onSite"
                required
              >
                <option>Choose the Onsite option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              <textarea
                type="text"
                className="c-sec3-form-p1"
                placeholder="Paste your resume here..."
                name="message"
                onChange={handleChange}
                required
                rows={3}
              />

              <button className="c-sec3-button"> Submit Message</button>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default ApplyNow;
