import React, { useState } from "react";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/i-exp-img2.png";
import Servexp2 from "../EXPimages/dm-rtm4-img4.png";
import { Link, useNavigate } from "react-router-dom";

const Domfin3 = () => {
  const nav =useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  
  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <h1 className="XS-sec1-two-h1">FINTECH</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Transform Your Fintech Operations with Our IT Solutions
          </h1>
          <p className="XS-sec1-two-p1">
            With our expertise in IT solutions, we can help you streamline your
            fintech operations and drive your business growth forward.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Secured transactions with robust cybersecurity
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            System integration
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Healthcare operations optimization
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Transform Your Fintech Operations with Teksett
          </h1>
          <p className="XS-sec1-two-p-p1">
            Protect your transactions with top-notch cybersecurity, automate
            financial processes for greater efficiency, and integrate your
            systems seamlessly with Teksett's expert IT solutions. Our robust
            cybersecurity solutions, automation tools, and system integration
            expertise can help your business stay ahead of the competition and
            achieve your goals.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          {/* <button className="XS-sec1-two-btn1">
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button> */}

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>Can your IT solutions
            handle complex financial calculations?
          </p>
          {isOpen1 && (
          <p className="XS-sec1-two-sp-pA">
            Yes, our automation tools are designed to handle everything from
            data entry to complex financial calculations, ensuring accuracy and
            efficiency in your financial operations.
          </p>
             )}
          <hr></hr>

          <p  onClick={() => setIsOpen2(!isOpen2)}   className="XS-sec1-two-sp-ps">
            <span>+</span>
            How can automation improve the efficiency of my financial
            operations?
          </p>
          {isOpen2 && (
          <p className="XS-sec1-two-sp-pA">
            By automating your financial processes, you can eliminate manual
            errors and streamline workflows, freeing up your staff to focus on
            more important tasks, ultimately improving productivity and reducing
            costs.
          </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> How can your advanced threat detection systems my
            business from cyber threats?
          </p>

          {isOpen3 && (

          <p className="XS-sec1-two-sp-pA">
            Our advanced threat detection systems are designed to detect and
            respond to cyber threats in real-time, helping to protect your
            business from potential breaches. By leveraging these systems, you
            can proactively identify and address security issues and minimize
            the risk of data breaches.
          </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)}   className="XS-sec1-two-sp-ps">
            <span>+</span> Can you help integrate our existing systems and
            applications to create a unified platform?
          </p>
          {isOpen4 && (
          <p className="XS-sec1-two-sp-pA">
            Yes, our team can help you integrate your existing systems and
            applications, including legacy systems, to create a unified platform
            that streamlines operations and improves decision-making.
          </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Retail & Manafacturing</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/domains/domrm4`}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>

            {/* <span className="XS-sec2-in2-d1-sp1">
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span> */}
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Domfin3;
