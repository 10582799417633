import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Ser1.css";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img8.png";
import Servexp2 from "../EXPimages/service-pd10-img10.png";

const Serv9pm = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  const nav = useNavigate();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">Project Managemenet</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Let us take the lead and deliver the project your business deserves.
          </h1>
          <p className="XS-sec1-two-p1">
            Our IT project management services are designed to help you maximize
            your IT investments, increase productivity, and achieve your
            business objectives.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Improved projects outcomes
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            increased efficiency
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Enhanced collaboration
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why choose Teksett for your project management
          </h1>
          <p className="XS-sec1-two-p-p1">
            Our team of experienced project managers is dedicated to ensuring
            that your projects are delivered on time, within budget, and to your
            satisfaction. We understand that effective project management is
            crucial to the success of any IT initiative, and we take pride in
            our ability to deliver high-quality results that meet the unique
            needs of our clients.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
            onClick={handleScrollToTop}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>What experience does your
            team have in IT project management?
          </p>

          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              Our team has years of experience in managing IT projects across
              various industries and has a proven track record of delivering
              successful projects.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> How do you ensure that projects are delivered on time
            and within budget?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              We use a structured project management methodology that involves
              careful planning, risk management, and regular communication with
              stakeholders to ensure that projects are delivered on time and
              within budget.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> Can you help us with project planning and scoping?
          </p>

          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              Yes, we offer project planning and scoping services to help you
              define your project requirements and create a detailed project
              plan.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> What communication channels do you use to keep
            clients informed about project progress?
          </p>
          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              We use a variety of communication channels to keep our clients
              informed about project progress, including regular status updates,
              progress reports, and project dashboards. We also schedule regular
              meetings with stakeholders to discuss project status, identify any
              issues or concerns, and adjust our approach as needed to ensure
              project success.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Product Development</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>
            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/services/serv10pd`}
              onClick={handleScrollToTop}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv9pm;
