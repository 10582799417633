import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import { scrollToElement } from "../util/component";

const Footer = () => {
  const nav = useNavigate();

  const handleOurStory = (id, val) => {
    nav("/aboutus", { state: val });
    setTimeout(() => {
      scrollToElement(id);
    }, 0);
  };

  const handleInsights = (id) => {
    nav("/insights");
    setTimeout(() => {
      scrollToElement(id);
    }, 0);
  };

  const handleMoveToSection = (id) => {
    scrollToElement(id);
  };

  const handleBlogs = (e) => {
    e.stopPropagation();
    nav("/");
    setTimeout(() => {
      handleMoveToSection("home-blog-list");
    }, 250);
  };

  return (
    <footer className="f-container" id="footer">
      <div className="f1-cont">
        <div className="f1-first">
          <div className="f1-logo-container">
            <h1>teksett</h1>
            <img className="f-logo" src="./PBimages/hf-logo-img1.png" alt="" />
          </div>
          <p id="f1-f-p1">
            14301 FNB Parkway Ste 100 <br></br>Omaha NE 68154
          </p>

          <p id="f-dash-a">_____</p>
          <section className="f-c-d">
            <section id="f1-right-one">
              <p>E-Mail</p>
              <p>Tel.</p>
            </section>
            <section id="f1-right-s1">
              <a href="mailto:bsetti@teksett.com">
                <p id="f-em-p">bsetti@teksett.com</p>
              </a>
              <a href="tel:+1 (402) 507-5290">
                {" "}
                <p id="f-em-p">+1 (402) 507-5290</p>{" "}
              </a>
            </section>
          </section>
        </div>

        <div className="f1-second">
          <h4 className="curs-pointer" onClick={() => nav("/aboutus")}>
            About
          </h4>
          <p
            className="curs-pointer"
            onClick={() =>
              handleOurStory("aboutus-corevalues", { ourStory: true })
            }
          >
            Our Story
          </p>
          <p
            className="curs-pointer"
            onClick={() =>
              handleOurStory("aboutus-corevalues", { coreValues: true })
            }
          >
            Our vision, mission & values
          </p>
          <p
            className="curs-pointer"
            onClick={() => handleMoveToSection("client-testimonial")}
          >
            Clients testimonial
          </p>
        </div>

        <div className="fl-third curs-pointer" onClick={() => nav("/services")}>
          <h4>Services</h4>
          <p>
            <a href="#IT Solutions delivery" className="custom-link">
              IT Solutions Delivery
            </a>
          </p>
          <p>
            {" "}
            <a href="#Staff Augmentation Services" className="custom-link">
              Staff Augmentation Services
            </a>
          </p>

          <p>
            {" "}
            <a href="#Data Sciences Solutions" className="custom-link">
              Data Sciences Solutions
            </a>
          </p>

          <p>
            {" "}
            <a href="#Business Development" className="custom-link">
              Business Development
            </a>
          </p>

          <p>
            <a href="#Offshore Development" className="custom-link">
              Offshore Development
            </a>
          </p>

          <p>
            <a href="#Software VAR" className="custom-link">
              Software VAR
            </a>
          </p>
          <p>
            <a href="#IT Consultancy" className="custom-link">
              IT Consultancy
            </a>
          </p>
          <p>
            <a href="#Corporate IT Training Services" className="custom-link">
              Corporate IT Training Services
            </a>
          </p>
          <p>
            <a href="#Project Management" className="custom-link">
              Project Management
            </a>
          </p>

          <p>
            <a href="#Product Development" className="custom-link">
              Product Development
            </a>
          </p>

          <p>
            <a href="#Data Engineering" className="custom-link">
              Data Engineering
            </a>
          </p>
        </div>

        <div className="f1-fourth">
          <h4 className="curs-pointer" onClick={() => nav("domains")}>
            Domains
          </h4>

          <p>
            <a href="#Healthcare" className="custom-link">
              Healthcare
            </a>
          </p>
          <p>
            <a href="#Agriculture" className="custom-link">
              Agriculture
            </a>
          </p>

          <p>
            <a href="#Fintech" className="custom-link">
              Fintech
            </a>
          </p>

          <p>
            <a href="#Retail&manufacturing" className="custom-link">
              Retail and Manufacturing
            </a>
          </p>

          <p>
            <a href="#Games&entertainment" className="custom-link">
              Games & entertainment
            </a>
          </p>

          <p>
            <a href="#Banking" className="custom-link">
              Banking
            </a>
          </p>

          <p>
            <a href="#Government" className="custom-link">
              Government
            </a>
          </p>
          <p>
            <a href="#Utilities" className="custom-link">
              Utilities
            </a>
          </p>

          <p>
            <a href="#Telecommunincation" className="custom-link">
              Telecommunication
            </a>
          </p>
          <p>
            <a href="#Insurance" className="custom-link">
              Insurance
            </a>
          </p>
        </div>

        <div className="f1-fifth">
          <h4 className="curs-pointer" onClick={() => nav("/insights")}>Insights</h4>
          <p className="curs-pointer" onClick={() => nav("/insights")}>
            Company insights
          </p>
          <p
            className="curs-pointer"
            onClick={() => handleInsights("insights-open-positions")}
          >
            Open position
          </p>
          <p
            className="curs-pointer"
            onClick={() => handleInsights("insights-work-benefits")}
          >
            {" "}
            Work Benefits
          </p>
          {/* <p className="curs-pointer">Location</p> */}
          <p className="curs-pointer" onClick={handleBlogs}>
            Blogs & Articles
          </p>
        </div>
      </div>
      <div className="f2-cont">
        <h5>OUR CLIENTS</h5>

        {/* <div className="f-sec2-imgs">
          <div className="f-sec2-img-d">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img1.png" />
          </div>

          <div className="h-sec2-img-d">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img2.png" />
          </div>
          <div className="h-sec2-img-d">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img3.png" />
          </div>
          <div className="h-sec2-img-d h-sec2-imgs-mbv">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img4.png" />
          </div>
          <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img5.png" />
          </div>
          <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img6.png" />
          </div>
          <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img7.png" />
          </div>
          <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img8.png" />
          </div>
          <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img9.png" />
          </div>
          <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
            <img className="h-sec2-imgs" src="./PBimages/f2-c-img10.png" />
          </div>
        </div> */}
        <ImLop />
        <p id="f2-p1"> &copy; Copyright 2023 by Teksett.</p>
      </div>
    </footer>
  );
};

const ImLop = () => {
  return (
    <div className="image-scroll">
      <div className="h-sec2-imgs" style={{ backgroundColor: "black" }}>
        <img className="f2img" src="PBimages/F2-img1.png" alt="" />
        <img className="f2img" src="PBimages/F2-img1.png" alt="" />
        <img className="f2img" src="PBimages/F2-img1.png" alt="" />
      </div>
    </div>
  );
};

export default Footer;
