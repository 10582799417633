import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Ser1.css";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img3.png";
import Servexp2 from "../EXPimages/service-ofd5-img5.png";

const Serv4ba = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  const nav = useNavigate();

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">Business Analytics</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Make smarter business decisions with our analytics solutions
          </h1>
          <p className="XS-sec1-two-p1">
            Our business analytics solutions will help you make data-driven
            decisions to drive your business forward. Empower your team with
            data insights that drive success
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Expertise in AI, ML, data engineering, and data science
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Actionable insights based on data analysis
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Expertise in data governance and management
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why Choose Teksett for your business analytics Solutions?
          </h1>
          <p className="XS-sec1-two-p-p1">
            Teksett is a trusted provider of business analytics solutions,
            specializing in BI tools such as Power BI, Tableau, and Python. We
            recognize the significance of data-driven decision-making in the
            modern business landscape. Our commitment lies in delivering
            expertly tailored solutions that cater specifically to your business
            requirements.
          </p>

          <Link className="XS-sec1-two-btn1" to="/contactus" >
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>What industries do you
            specialize in?
          </p>
          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              We specialize in providing business analytics solutions for a wide
              range of industries, including healthcare, finance, manufacturing,
              insurance, retail, and more.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> What kind of data do you work with?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              We excel at handling diverse data types: structured, unstructured,
              big data, and IoT data. With BI tools like Power BI, Tableau, and
              Python, we transform this data into actionable intelligence for
              informed decision-making.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> Can you customize your solutions to meet our specific
            business needs?
          </p>

          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              Yes, we offer customized solutions tailored to your unique
              business needs. Our team will work with you to identify your
              specific requirements and develop a solution that meets your
              needs.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> How do you incorporate AI and ML into your solutions?
          </p>
          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              Our solutions seamlessly integrate AI and ML techniques, including
              Master Data Management and Data Governance processes. By
              leveraging these advanced technologies, we can help you
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>OffShore Developement</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>
            <div
              className="XS-sec2-in2-d1-sp1"
              onClick={() => nav("/services/serv5od")}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </div>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv4ba;
