import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Services from "./pages/Services";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Domains from "./pages/Domains";
import Insights from "./pages/Insights";
import Contactus from "./pages/Contactus";
import Products from "./pages/Products";
import Careers from "./pages/Careers";

import Ser1 from "./Expandedservices/Ser1";
import Dom1 from "./Expdomains/Dom1";

import Serv2sas from "./Expandedservices/Serv2sas";
import Serv3dss from "./Expandedservices/Serv3dss";
import Serv4ba from "./Expandedservices/Serv4ba";
import Serv5od from "./Expandedservices/Serv5od";
import Serv6svar from "./Expandedservices/Serv6svar";
import Serv7itc from "./Expandedservices/Serv7itc";
import Serv8cits from "./Expandedservices/Serv8cits";
import Serv9pm from "./Expandedservices/Serv9pm";
import Serv10pd from "./Expandedservices/Serv10pd";
import Serv11de from "./Expandedservices/Serv11de";

import Domag2 from "./Expdomains/Domag2";
import Domfin3 from "./Expdomains/Domfin3";
import Domrm4 from "./Expdomains/Domrm4";
import Domgm5 from "./Expdomains/Domgm5";
import Dombank6 from "./Expdomains/Dombank6";
import Domgov7 from "./Expdomains/Domgov7";
import Domult8 from "./Expdomains/Domult8";
import Domtele9 from "./Expdomains/Domtele9";
import Dominsu10 from "./Expdomains/Dominsu10";
import Htabs from "./Htabs/Htabs";
import Blogexp1 from "./Blogsexp/Blogexp1";
import Blogexp2 from "./Blogsexp/Blogexp2";
import Blogexp3 from "./Blogsexp/Blogexp3";
import Blogexp4 from "./Blogsexp/Blogexp4";
import ApplyNow from "./pages/Insights_applynow";
import ScrollToTop from "./util/scrollToTop";
import Blogs from "./pages/Blogs";

function App() {


  return (
    <div>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home/blogexp1" element={<Blogexp1 />} />
        <Route path="/home/blogexp2" element={<Blogexp2 />} />
        <Route path="/home/blogexp3" element={<Blogexp3 />} />
        <Route path="/home/blogexp4" element={<Blogexp4 />} />

        <Route path="/blogs/blogexp1" element={<Blogexp1 />} />
        <Route path="/blogs/blogexp2" element={<Blogexp2 />} />
        <Route path="/blogs/blogexp3" element={<Blogexp3 />} />
        <Route path="/blogs/blogexp4" element={<Blogexp4 />} />


        {/* <Route path="/home/htabs" element={<Htabs />} /> */}
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/ser1" element={<Ser1 />} />
        <Route path="/services/serv2sas" element={<Serv2sas />} />
        <Route path="/services/serv3dss" element={<Serv3dss />} />
        <Route path="/services/serv4ba" element={<Serv4ba />} />
        <Route path="/services/serv5od" element={<Serv5od />} />
        <Route path="/services/serv6svar" element={<Serv6svar />} />
        <Route path="/services/serv7itc" element={<Serv7itc />} />
        <Route path="/services/serv8cits" element={<Serv8cits />} />
        <Route path="/services/serv9pm" element={<Serv9pm />} />
        <Route path="/services/serv10pd" element={<Serv10pd />} />
        <Route path="/services/serv11de" element={<Serv11de />} />
        <Route path="/domains" element={<Domains />} />
        <Route path="/domains/dom1" element={<Dom1 />} />
        <Route path="/domains/domag2" element={<Domag2 />} />
        <Route path="/domains/domfin3" element={<Domfin3 />} />
        <Route path="/domains/domrm4" element={<Domrm4 />} />
        <Route path="/domains/domgm5" element={<Domgm5 />} />
        <Route path="/domains/dombank6" element={<Dombank6 />} />
        <Route path="/domains/domgov7" element={<Domgov7 />} />
        <Route path="/domains/domult8" element={<Domult8 />} />
        <Route path="/domains/domtele9" element={<Domtele9 />} />
        <Route path="/domains/dominsu10" element={<Dominsu10 />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/products" element={<Products />} />
      
        <Route path="/careers" element={<Careers />} />
        <Route path="/blogs" element={<Blogs />} />

        <Route path="/contactus" element={<Contactus />} />
        <Route path="/htabs" element={<Htabs />} />
        <Route path="/applynow" element={<ApplyNow />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
