import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Ser1.css";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img6.png";
import Servexp2 from "../EXPimages/service-cits8-img8.png";

const Serv7itc = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  const nav=useNavigate()
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">IT CONSULTENCY</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Expert IT Consultancy Services to Transform Your Business
          </h1>
          <p className="XS-sec1-two-p1">
            At Teksett, we take pride in our years of experience in the
            industry. Our team of experts is well-equipped to provide you with
            tailored solutions that meet your unique IT needs.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Cost Savings
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            competitive Edge
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Enchanced Security
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Get Reliable IT Solutions Tailored for You
          </h1>
          <p className="XS-sec1-two-p-p1">
            Our IT consultancy services aim to provide you with improved
            efficiency and productivity through streamlined business operations.
            We also prioritize enhanced security and compliance to ensure their
            systems and processes meet the latest security standards and
            regulations.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
            onClick={handleScrollToTop}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>What types of IT
            consultancy services do you offer?
          </p>
          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              We offer a range of IT consultancy services, including IT strategy
              consulting, cybersecurity consulting, cloud consulting, User
              experience consulting, data managed services and more. Our
              services are tailored to meet your unique business needs.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> How can IT consultancy services improve my business
            operations?
          </p>

          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              Our IT consultancy services can help improve your business
              operations by identifying inefficiencies, implementing the latest
              technologies, and optimizing your processes to boost productivity
              and profitability.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> How do you ensure the security of my business data?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              We take security very seriously and ensure that your systems and
              processes are compliant with the latest security standards and
              regulations. We also conduct regular security assessments and
              provide ongoing monitoring to detect and prevent potential
              threats.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> What kind of cost savings can I expect from your IT
            consultancy services?
          </p>
          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              Our IT consultancy services can help you identify cost-saving
              opportunities and optimize your IT spend. We'll work with you to
              develop a customized plan that aligns with your budget and goals,
              ensuring that you get the most value for your investment.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Corporate IT Training Services</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>
            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/services/serv8cits`}
              onClick={handleScrollToTop}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv7itc;
