import React from "react";
import "./Domains.css";
import { useNavigate } from "react-router-dom";

const Domain = () => {
  const navigate = useNavigate();

  const handleMoveToPage = (page) => {
    navigate(page);
  };

  return (
    <>
      <div className="d-sec1">
        <span className="d-sec1-s1">TEKSETT &lt; DOMAINS</span>
        <h1 className="d-sec1-h1">
          Revolutionizing industries with <br></br>{" "}
          <span className="d-sec1-sp2">Teksett's IT Solutions</span>
        </h1>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/dm-h1-img1.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Healthcare">Healthcare</h1>
            <p>
              With our expert IT services, your healthcare practice can focus on
              what matters most: providing exceptional patient care. Our
              expertise in the industry and range of IT services make us the
              perfect partner to take your healthcare business to the next level
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Streamline Patient Management
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Data Security and Compliance
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Healthcare operations optimization
            </span>
          </div>

          <div className="S-sec2-d2-in2 d-tbv-bt-3">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/dom1")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/dm-ag2-img2.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Agriculture">Agriculture</h1>
            <p>
              Teksett provides tailored IT solutions for the agriculture
              industry to enhance efficiency, productivity, and profitability
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Smart Agriculture
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Precision Farming
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Agribusiness Management
            </span>
          </div>

          <div className="S-sec2-d2-in2 d-tbv-bt-4">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/domag2")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/dm-ft3-img3.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Fintech">Fintech</h1>
            <p>
              With our expertise in IT solutions, we are uniquely positioned to
              help your fintech company streamline operations and drive business
              growth.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Secured transactions with robust cybersecurity
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              System integration
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Finance process automation
            </span>
          </div>

          <div className="S-sec2-d2-in2 d-tbv-bt-5">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/domfin3")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/d-sec2-img4-four.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Retail&manufacturing">Retail and manufacturing</h1>
            <p>
              Streamline your operations and improve your bottom line with
              Teksett's tailored IT services for the retail and manufacturing
              industry. Our tailored approach ensures that we understand your
              unique needs and deliver the solutions that meet them.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Optimized Supply Chain Management
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Robust Data Analytics
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Enhanced Customer Experience
            </span>
          </div>

          <div className="S-sec2-d2-in2 d-tbv-bt-6">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/domrm4")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/dm-gm5-img5.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Games&entertainment"> Gaming & entertainment</h1>
            <p>
              From customized software development to cloud solutions and
              cybersecurity, we have the expertise and experience to help you
              navigate the constantly evolving landscape of the gaming industry.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Gaming-Specific IT Solutions
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Entertainment industry IT Solutions
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Creation of Immersive Experiences for customers
            </span>
          </div>

          <div className="S-sec2-d2-in2 d-tbv-bt-7">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/domgm5")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/dm-bank6-img6.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Banking">Banking</h1>
            <p>
              Our IT services are designed to help banks enhance their
              operational efficiency, streamline processes, and reduce costs
              while maintaining the highest levels of security.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Expert-led team and professionals
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Ongoing support and maintenance
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Reliable and scalable banking solutions
            </span>
          </div>

          <div className="S-sec2-d2-in2 d-tbv-bt-8">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/dombank6")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/dm-gov7-img7.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Government">Government</h1>
            <p>
              We provide reliable and secure IT services that help government
              agencies streamline their operations and improve their service
              delivery.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Robust and secure IT infrastructure
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Expertise in implementing
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Deep understanding of government operations and regulations
            </span>
          </div>

          <div className="S-sec2-d2-in2 d-tbv-bt-8">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/domgov7")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/d-sec2-img8-eight.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Utilities">Utilities</h1>
            <p>
              Teksett excels in delivering tailored IT services and solutions
              for the utilities industry. With industry-specific expertise,
              robust infrastructure, and adherence to regulatory standards, we
              ensure optimal performance and reliability for utility operations.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Comprehensive Utilities Expertise
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Regulatory compliance and Data Integrity
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Robust Infrastructure for Mission-Critical Systems
            </span>
          </div>

          <div className="S-sec2-d2-in2 d-tbv-bt-9">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/domult8")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/d-sec2-img9-nine.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Telecommunincation">Telecommunincation</h1>
            <p>
              In a world where communication is key, your business needs IT
              solutions that can keep up with the speed of technology. Our team
              of experts at Teksett has a deep understanding of the latest
              trends and technologies, and we are committed to helping your
              business succeed.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Network Infrastructure Management
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Telecom-Speific IT Consultancy
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Software Development for Telecommunications
            </span>
          </div>

          <div className="S-sec2-d2-in2">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/domtele9")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/dm-insu10-img10.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Insurance">Insurance</h1>
            <p>
              Our well-designed IT solutions ensure that you experience reliable
              IT support tailored to your insurance business needs.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Customized solutions to meet your business needs
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              streamlined wokflows for increased efficiency
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Expertise in insurance-specific software and systems
            </span>
          </div>

          <div className="S-sec2-d2-in2 d-tbv-bt-10">
            <div
              className="S-sec2-btn"
              onClick={() => handleMoveToPage("/domains/dominsu10")}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Domain;
