import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Htabs.css";

const Htabs = () => {
  return (
    <>
      <div id="tbv-nvbr">
        <div className="tb-v-sec2">
          <ul className="tab-ul">
            <li id="tab-li">
              {" "}
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              {" "}
              <NavLink to="/aboutus">About Us</NavLink>
            </li>
            <li>
              {" "}
              <NavLink to="/services">Services</NavLink>
            </li>
            <li>
              {" "}
              <NavLink to="/domains">Domains</NavLink>
            </li>
            <li>
              {" "}
              <NavLink to="/insights">Insights</NavLink>
            </li>
          </ul>
        </div>

        <section className="tb-sec3">
          <Link className="tbrand" to="/">
            teksett
          </Link>
          <img className="c-logo-t" src="./PBimages/hf-logo-img1.png" />
        </section>
        <p className="tb-sec2-p1">
          14301 FNB Parkway Ste 100<br></br>Ohmaha NE 68154
        </p>
        <p className="tb-sec2-p2">Conatct Us </p>

        <section className="tb-sec4">
          <section>
            <p>E-Mail</p>
            <p>Tel</p>
          </section>

          <section className="tb-sec4-d2">
            <p>bsetti10@gmail.com</p>
            <p>+14025075290</p>
          </section>
        </section>
      </div>
    </>
  );
};

export default Htabs;
