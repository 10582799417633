import React from "react";
import "./Services.css";
import { Link } from "react-router-dom";

const Services = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }
  return (
    <>
      <div className="S-sec1">
        <span className="S-sec1-sp1">TEKSETT &lt; SERVICES</span>
        <h1 className="S-sec1-h1">
          Teksett: Providing you with Top-notch <br></br>
          <span className="S-sec1-sp2"> IT solutions for your business</span>
        </h1>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/S-sec2-img1-one.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="IT Solutions delivery">IT Solutions delivery</h1>
            <p>
              At Teksett, we understand that your business relies on efficient
              and effective IT solutions. That's why we offer a comprehensive
              range of services to help you streamline your operations, reduce
              costs, and maximize productivity.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              High level of expertise
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Customized IT Solutions
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Collaborative approach to work
            </span>
          </div>

          <div className="S-sec2-d2-in2">

            <Link
              className="S-sec2-btn"
              to={`/services/ser1`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
            
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/service-sts2-img2.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Staff Augmentation Services">
              Staff Augmentation services
            </h1>
            <p>
              Our IT staffing services offer a range of solutions to help you
              find the right talent for your business needs. From web developers
              to IT recruiters, our team of experts can help you find the
              perfect candidate to fill your IT staffing requirements.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Cost-effective
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Access to top 5% talents
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Reduce hiring time
            </span>
          </div>

          <div className="S-sec2-d2-in2">
            <Link
              className="S-sec2-btn"
              to={`/services/serv2sas`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/S-sec2-img3-three.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Data Sciences Solutions">Data Science Solutions</h1>
            <p>
              Harness the power of your data with Teksett's expertise in machine
              learning algorithms and advanced statistical tools. Our tailored
              solutions drive actionable insights and empower you to make
              informed decisions for exponential growth
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Precise predictive modeling capabilities
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Robust data analysis techniques
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Reliable and accurate results
            </span>
          </div>

          <div className="S-sec2-d2-in2">
            <Link
              className="S-sec2-btn"
              to={`/services/serv3dss`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/S-sec2-img4-four.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Business Development"> Business Analytics</h1>
            <p>
              Our business analytics solutions will help you make data-driven
              decisions to drive your business forward. Empower your team with
              data insights that drive success.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Improved business performance
            </span>
            {/* <br></br> */}

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Actionable insights besed on data analysis
            </span>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Expertise in AI,ML, data engineering, and data science
            </span>
          </div>

          <div className="S-sec2-d2-in2 tbv-bt-6">
            <Link
              className="S-sec2-btn"
              to={`/services/serv4ba`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/service-ofd5-img5.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Offshore Development">Offshore Development</h1>
            <p>
              Teksett excels in offshore development services, allowing seamless
              collaboration with your team, regardless of geographical
              constraints. Our comprehensive IT solutions are tailored to meet
              your organization's specific needs and deliver optimal results.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Global Talent Access
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Seamless Collaboration
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Cost-Effective Solutions
            </span>
          </div>

          <div className="S-sec2-d2-in2">
            <Link
              className="S-sec2-btn"
              to={`/services/serv5od`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/service-sv6-img6.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Software VAR">Software VAR(Value-added Reseller)</h1>
            <p>
              As a value-added reseller (VAR) for Microsoft and Oracle, we
              provide comprehensive software solutions that enable businesses to
              increase efficiency, productivity, and profitability.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Comprehsive software solutions
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Expert support
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Streamlined processes
            </span>
          </div>

          <div className="S-sec2-d2-in2 tbv-bt-7">
            <Link
              className="S-sec2-btn"
              to={`/services/serv6svar`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/service-itc7-img7.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="IT Consultancy">IT Consultancy</h1>
            <p>
              At Teksett, we take pride in our years of experience in the
              industry. Our team of experts is well-equipped to provide you with
              tailored solutions that meet your unique IT needs.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Cost Savings
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Competitive Edge
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Enchanced Security
            </span>
          </div>

          <div className="S-sec2-d2-in2 tbv-bt-7">
            <Link
              className="S-sec2-btn"
              to={`/services/serv7itc`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/service-cits8-img8.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Corporate IT Training Services">
              Corporate IT Training Services
            </h1>
            <p className="tbv-bt-8-p">
              At Teksett, we understand that the success of your business
              depends on the knowledge and skills of your team. That's why we
              offer comprehensive corporate IT training services designed to
              enhance your team's capabilities and help your organization
              achieve its goals.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Enhanced Employee Retention
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Cost-effective
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Customized Training Programs
            </span>
          </div>

          <div className="S-sec2-d2-in2 tbv-bt-8">
            <Link
              className="S-sec2-btn"
              to={`/services/serv8cits`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/service-pm9-img9.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Project Management">Project Management</h1>
            <p>
              Our IT project management services are designed to help you
              maximize your IT investments, increase productivity, and achieve
              your business objectives.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Improved project outcomes
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Increased efficiency
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Enchanced collaboration
            </span>
          </div>

          <div className="S-sec2-d2-in2 tbv-bt-9">
            <Link
              className="S-sec2-btn"
              to={`/services/serv9pm`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/service-pd10-img10.png" alt="" />
        </div>

        <div className="S-sec2-d2" >
          <div className="S-sec2-d2-in1">
            <h1 id="Product Development">Product development</h1>
            <p>
              From ideation to launch, we'll be with you every step of the way.
              Our product development process is designed to help you innovate
              and stay ahead of the competition.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Experienced developers and designers
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Quick turnaround times and project management
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Continues communication and transparency
            </span>
          </div>

          <div className="S-sec2-d2-in2 tbv-bt-10">
            <Link
              className="S-sec2-btn"
              to={`/services/serv10pd`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="S-sec2">
        <div className="S-sec2-d1">
          <img src="./PBimages/service-de11-img11.png" alt="" />
        </div>

        <div className="S-sec2-d2">
          <div className="S-sec2-d2-in1">
            <h1 id="Data Engineering">Data Engineering </h1>
            <p>
              Teksett specializes in delivering expert data engineering
              services, offering clients efficient and reliable solutions for
              effective data management and optimization.
            </p>
            <h6>Top Benefits</h6>

            <span className="S-sec2-d2-in1-sp1">
              <img src="./PBimages/Blue-dot.png" alt="" />
              Advanced Data Analytics
            </span>

            <span className="S-sec2-d2-in1-sp1">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Data Quality and Integrity
            </span>
            <br></br>

            <span className="S-sec2-d2-in1-sp2">
              {" "}
              <img src="./PBimages/Blue-dot.png" alt="" />
              Scalable Data Solutions
            </span>
          </div>

          <div className="S-sec2-d2-in2 tbv-bt-11">
            <Link
              className="S-sec2-btn"
              to={`/services/serv11de`}
              onClick={handleScrollToTop}
            >
              View Details
              <span className="S-sec2-btn-sp-d">|</span>
              <img
                className="S-sec2-AR"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="empty"></div>
    </>
  );
};

export default Services;
