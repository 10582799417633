import React, { useState } from "react";
import "./Contactus.css";
import { useNavigate } from "react-router-dom";
import { createContactus } from "../api/requestDemo";

const initialStates = {
  portal_name: "Teksett",
  requested_demo_for_product_name: "Teksett portal",
  first_name: "",
  last_name: "",
  orgnization_email: "",
  orgnization_name: "",
  message: "",
};

const Contactus = () => {
  const nav = useNavigate();
  const [formData, setFormData] = useState(initialStates);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await createContactus(formData);
      setFormData(initialStates);
      alert(res.data.message);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div className="C-sec">
        <div className="c-sec-1">
          <img className="ct-img" src="./PBimages/contactimg.png" alt="" />
        </div>

        <div className="c-sec-2">
          <span className="c-sec-2-sp1">
            {" "}
            <span className="XS-sec1-two-sp1" onClick={() => nav("/")}>
              <span className="" style={{ padding: "2px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="9"
                  viewBox="0 0 7 12"
                  fill="none"
                >
                  <path
                    d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                    fill="black"
                  />
                </svg>{" "}
              </span>
              Back to Home
            </span>
          </span>
          <h1 className="c-sec-2-h1">
            Get in Touch <span>with Us</span>{" "}
          </h1>

          <section className="c-sec3">
            <section className="c-sec3-1">
              <p className="c-sec3-1-p1">
                Any questions? Then write to us! Fill out the form below and one
                of our Teksett consultants will get in touch with you as soon as
                possible.
              </p>
            </section>

            <section className="c-sec3-2">
              <h6 className="c-sec3-2-h6">Tesksett</h6>
              <p className="c-sec3-2-p2">
                14301 FNB Parkway Ste 100 <br></br>
                Omaha NE 68154
              </p>
              <p className="c-sec3-2-p3">___</p>

              <section className="c-sec3-in2">
                <span className="c-sec3-in2-sp1">
                  E-Mail<br></br>Tel.
                </span>
                <span className="c-sec3-in2-sp2">
                  bsetti@teksett.com <br></br>+1 (402) 507-5290
                </span>
              </section>
            </section>

            <form onSubmit={handleSubmit} className="c-sec3-form">
              <h6 className="c-sec3-form-h6">
                Get in Touch by Filling this Form
              </h6>
              <input
                type="text"
                className="c-sec3-form-p1"
                name="first_name"
                placeholder="FirstName"
                onChange={handleChange}
                required
                value={formData?.first_name}
              />
              <input
                type="text"
                className="c-sec3-form-p1"
                placeholder="LastName"
                name="last_name"
                onChange={handleChange}
                required
                value={formData?.last_name}
              />
              <input
                type="email"
                className="c-sec3-form-p1"
                placeholder="Email"
                name="orgnization_email"
                onChange={handleChange}
                value={formData?.orgnization_email}
              />
              <input
                type="text"
                className="c-sec3-form-p1"
                placeholder="CompanyName"
                name="orgnization_name"
                onChange={handleChange}
                value={formData?.orgnization_name}
              />
              <input
                type="text"
                className="c-sec3-form-p2"
                placeholder="Your Message"
                name="message"
                onChange={handleChange}
                value={formData?.message}
              />
              <button className="c-sec3-button"> Submit Message</button>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default Contactus;
