import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import "./Ser1.css";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img5.png";
import Servexp2 from "../EXPimages/service-itc7-img7.png";

const Serv6svar = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  const nav=useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">Software VAR(Value-added Reseller)</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            Trusted Microsoft, SAP, SAAS and Oracle Software VAR
          </h1>
          <p className="XS-sec1-two-p1">
            As a value-added reseller (VAR) for Microsoft, Oracle, SAP and SAAS
            we provide comprehensive software solutions that enable businesses
            to increase efficiency, productivity, and profitability.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Comprehensive software solutions
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Streamlined processes
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Expert support
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why Choose Teknett for your softwares?
          </h1>
          <p className="XS-sec1-two-p-p1">
            As a trusted VAR (Value-Added Reseller), we specialize in providing
            comprehensive software solutions from industry leaders like
            Microsoft and Oracle. Our expert team collaborates with you to
            identify and seamlessly implement the most suitable solutions,
            including SAP and SAAS. We also offer ongoing support to ensure your
            software is up-to-date, secure, and running smoothly.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
            onClick={handleScrollToTop}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>How do I know which
            software solutions are right for my business?
          </p>
          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              Our team of experts will work with you to understand your business
              needs and recommend the most suitable solutions from Microsoft and
              Oracle.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> Will implementing new software solutions disrupt my
            business operations?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              No, we take great care to ensure a smooth implementation process
              that minimizes disruption to your business operations.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> Will implementing SAAS or SAP disrupt my existing IT
            infrastructure?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              No, SAAS and SAP implementations are carefully planned to minimize
              disruptions. Our team ensures a seamless integration with your
              existing systems, allowing for a smooth transition and
              uninterrupted business operations.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> What kind of support do you provide for your software
            solutions?
          </p>
          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              We provide expert support to ensure your software solutions are
              always up-to-date, secure, and running smoothly. Our team is
              always available to answer any questions and provide guidance
              whenever you need it.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>IT Consultency</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/services/serv7itc`}
              onClick={handleScrollToTop}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv6svar;
