import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Ser1.css";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/s-exp-img7.png";
import Servexp2 from "../EXPimages/service-pm9-img9.png";

const Serv8cits = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }
  const nav=useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/services")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Services
          </span>
          <h1 className="XS-sec1-two-h1">Corporate IT Training services</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Reach your IT career goals with our expert-led trainings
          </h1>
          <p className="XS-sec1-two-p1">
            At Teksett, we offer top-notch IT training and consultancy services
            to help you stay ahead in the rapidly changing world of technology.
            Whether you're looking to enhance your skills, earn certifications,
            or receive expert guidance on IT support, our courses are designed
            to meet your unique needs.
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Enhanced Employee Retention
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Customized Training Programs
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Cost-effective
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Why Choose Teksett for IT Support Training
          </h1>
          <p className="XS-sec1-two-p-p1">
            At Teksett, we understand that IT support is a critical function in
            any organization, which is why we offer customized training
            solutions tailored to your specific needs. Our instructors are
            experienced IT professionals who are passionate about sharing their
            knowledge with you.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
            onClick={handleScrollToTop}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p onClick={() => setIsOpen1(!isOpen1)} className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span>What kind of IT
            certifications do you offer?
          </p>

          {isOpen1 && (
            <p className="XS-sec1-two-sp-pA">
              We offer a wide range of industry-recognized IT certifications,
              including Microsoft, CompTIA, Cisco, AWS, and more. Our training
              programs are designed to help you pass these certifications and
              gain the skills and knowledge you need to succeed in your IT
              career.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> Can I choose my own training schedule?
          </p>
          {isOpen2 && (
            <p className="XS-sec1-two-sp-pA">
              Yes, we offer flexible training schedules to suit your needs. You
              can choose from a range of training options, including online
              courses, in-person classes, and customized training programs.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> Who will be providing the training?
          </p>
          {isOpen3 && (
            <p className="XS-sec1-two-sp-pA">
              Our training programs are delivered by industry experts with years
              of experience in the IT industry. Our trainers are certified
              professionals who have a deep understanding of the latest
              technologies and trends in the field.
            </p>
          )}
          <hr></hr>

          <p onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span> What kind of success have you had with previous
            clients?
          </p>

          {isOpen4 && (
            <p className="XS-sec1-two-sp-pA">
              We have a proven track record of success with satisfied clients
              across a range of industries. Our training and consultancy
              services have helped businesses optimize their IT infrastructure
              and systems, improve their productivity and efficiency, and
              achieve their long-term growth goals.
            </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Project Management</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/services/serv9pm`}
              onClick={handleScrollToTop}
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv8cits;
