import React from "react";
import "./Careers.css";
import { Link, NavLink } from "react-router-dom";

const Careers = () => {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }
  return (
    <>
      <div className="C-sec1">
        <span>TEKSETT &lt; CAREER</span>
        <h1> Careers </h1>
      </div>

      <div className="I-sec3">
        <div className="I-sec3-one" id="insights-open-positions">
          <span>CAREERS</span>
        </div>

        <div className="I-sec3-two">
          <h1>
            View open <span>positions </span>
          </h1>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>Project Manager</h4>
              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <span className="i-sec3-span2"> 3-5 Years</span>
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>Senior IT Sales</h4>

              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <sapn className="i-sec3-span2"> 3-5 Years</sapn>{" "}
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>Marketing Associate</h4>

              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <sapn className="i-sec3-span2"> 3-5 Years</sapn>{" "}
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>Oracle ERP Associate</h4>
              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <sapn className="i-sec3-span2"> 3-5 Years</sapn>{" "}
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div className="I-sec3-in1">
            <div className="I-sec-two-s1">
              <h4>SAP Utilities</h4>

              <p className="I-sec-two-sp1">
                Location:<span> Omaha, NE, USA</span>{" "}
                <br className="I-sec-two-br1"></br>Experience:
                <sapn className="i-sec3-span2"> 3-5 Years</sapn>{" "}
              </p>
            </div>

            <div className="I-sec-two-s2">
              <span style={{ padding: "1.4vw" }}>
                <svg
                  width="1"
                  height="78"
                  viewBox="0 0 1 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    opacity="0.1"
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499994"
                    y2="140"
                    stroke="white"
                  />
                </svg>
              </span>
              <button className="I-sec3-btn">
                <NavLink
                  to={"/applynow"}
                  className={"custom-link"}
                  onClick={handleScrollToTop}
                >
                  Apply Now
                </NavLink>
                <span>|</span>
                <img
                  className="I-sec3-btn-A"
                  src="./PBimages/WhiteArrow.png"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
