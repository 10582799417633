import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import video1 from "../svideos/finel.gif";
import { Link } from "react-router-dom";

const Home = () => {
  const nav = useNavigate();

  return (
    <>
      <div className="H-sec1">
        <div className="H-sec1-first">
          <span> YOUR TRUSTED IT PARTNER SINCE 1998</span>
          <h1>
            <span> Modern IT solutions</span>
            <br></br> to scale your Business
          </h1>

          <Link className="h-sec1-btn" to={`/contactus`}>
            {" "}
            Get in Touch <span className="sp-div">|</span>
            <img className="h-sec1-A1" src="./PBimages/WhiteArrow.png" alt="" />
          </Link>

          {/* <button className="h-sec1-btn">
            {" "}
            Get in Touch <span className="sp-div">|</span>
            <img className="h-sec1-A1" src="./PBimages/WhiteArrow.png" alt="" />
          </button> */}

          <h6 id="sec2-oc">OUR CLIENTS</h6>
        </div>

        <div className="H-sec1-second">
          <img className="H-sec1-img1" src="./PBimages/h-sec1-img1.png" />
        </div>
      </div>

      {/* section 2 images */}

      {/* <div className="h-sec2-imgs">
        <div className="h-sec2-img-d">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img1.png" />
        </div>

        <div className="h-sec2-img-d">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img2.png" />
        </div>
        <div className="h-sec2-img-d">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img3.png" />
        </div>
        <div className="h-sec2-img-d h-sec2-imgs-mbv">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img4.png" />
        </div>
        <div className="h-sec2-img-d h-sec2-imgs-mbv">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img5.png" />
        </div>
        <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img6.png" />
        </div>
        <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img7.png" />
        </div>
        <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img8.png" />
        </div>
        <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img9.png" />
        </div>
        <div className="h-sec2-img-d h-sec2-imgs-tabv h-sec2-imgs-mbv">
          <img className="h-sec2-imgs" src="./PBimages/h-sec2-imgs-img10.png" />
        </div>
      </div> */}

      <ImLop />

      <div className="H-sec3">
        <div className="h-sec3-first">
          <span>WHO WE ARE</span>
        </div>

        <div className="h-sec3-second">
          <h1>
            We're here to help <br></br> you <span>Succeed</span>
          </h1>
          <p>
            {" "}
            We're a team of tech-savy professionals who are passionate about
            finding the perfect IT solution for all your business needs. From
            providing cutting- edge solutions to Customized staffing solutions,
            we go above and beyond to ensure your success.
          </p>

          <Link className="H-sec3-B" to={`/aboutus`}>
            Learn More
          </Link>
          {/* <button  onClick={() => navigate("/aboutus")} 
          
          className="H-sec3-B">Learn More</button> */}
        </div>
      </div>

      <div className="H-sec4">
        <img className="h-sec4-img" src="./PBimages/H-sec4-img.png" />
        <img className="h-sec4-img-mbv" src="./PBimages/H-sec4-mbv-img.png" />
      </div>

      <div className="H-sec5">
        <div className="h-sec5-one">
          <span>WHAT WE DO</span>
          <h1>teksett.</h1>
        </div>

        <div className="h-sec5-two" onClick={() => nav("/services")}>
          <div className="h-sec5-two-d1">
            <div className="h-sec5-two-hw1">
              <h1>
                <a href="#IT Solutions delivery" className="custom-link">
                  IT Solutions Delivery
                </a>
              </h1>
            </div>

            <div className="sss2">
              <img
                className="h-sec5-hwimg"
                src="PBimages/h-sec3-hw-img4.png"
                alt=""
              />
            </div>
          </div>

          <div className="h-sec5-two-d1">
            <div className="h-sec5-two-hw1">
              <h1>
                <a href="#Staff Augmentation Services" className="custom-link">
                  Staff Augmentation Services
                </a>
              </h1>
            </div>

            <div className="sss2">
              <img
                className="h-sec5-hwimg"
                src="PBimages/h-sec3-hw-img5.png"
                alt=""
              />
            </div>
          </div>

          <div className="h-sec5-two-d1">
            <div className="h-sec5-two-hw1">
              <h1>
                <a href="#Data Sciences Solutions" className="custom-link">
                  Data Sciences Solutions
                </a>
              </h1>
            </div>

            <div className="sss2">
              <img
                className="h-sec5-hwimg"
                src="PBimages/H-sec5-hw-img3.png"
                alt=""
              />
            </div>
          </div>

          <div className="h-sec5-two-d1">
            <div className="h-sec5-two-hw1">
              <h1>
                <a href="#Business Development" className="custom-link">
                  Business Development
                </a>
              </h1>
            </div>

            <div className="sss2">
              <img
                className="h-sec5-hwimg"
                src="PBimages/H-sec5-hw-img4.png"
                alt=""
              />
            </div>
          </div>

          <div className="h-sec5-two-d1">
            <div className="h-sec5-two-hw1">
              <h1>
                <a href="#Offshore Development" className="custom-link">
                  Offshore Development
                </a>
              </h1>
            </div>

            <div className="sss2">
              <img
                className="h-sec5-hwimg"
                src="PBimages/H-sec5-hw-img5.png"
                alt=""
              />
            </div>
          </div>

          <div>
            <Link className="h-sec5-two-btn" to={`/services`}>
              {" "}
              View All
              <span>|</span>
              <img
                className="h-sec5-A1"
                src="./PBimages/WhiteArrow.png"
                alt=""
              />
            </Link>
            {/* <button
              className="h-sec5-two-btn"
              onClick={() => navigate("/services")}
            >
              View All
              <span>|</span>
              <img
                className="h-sec5-A1"
                src="./PBimages/WhiteArrow.png"
                alt=" "
              />
            </button> */}
          </div>
        </div>
      </div>

      <div className="H-sec6-one">
        <div className="H-sec6-one-d1">
          <span>WHY TEKSETT</span>
        </div>

        <div className="H-sec6-one-d2">
          <h1>
            Experience Top-Notch
            <br></br> <span>IT Solutions with Teksett</span>
          </h1>
          <img className="h-sec6-one-bb1" src="./PBimages/black-blue-img.png" />
          <p>
            Partner with Teksett for trusted, top-notch IT solutions that
            deliver<br></br> excellent results tailored to your business needs.
          </p>
        </div>
      </div>

      <div className="H-sec6-three">
        <div
          className="H-sec6-three-d1"
          style={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={video1} height={"250px"} width={"auto"} />

          {/* <div>
            <video
              className="H-sec6-three-d1-vd1"
              src={video1}
              controls="controls"
              autoPlay="true"
            />
          </div> */}

          {/* <div>
            <video
              className="H-sec6-three-d1-vd1"
              src={video2}
              controls="controls"
              autoPlay="true"
            />
          </div>

          <div>
            <video
              className="H-sec6-three-d1-vd1"
              src={video3}
              controls="controls"
              autoPlay="true"
            />
          </div>

          <div>
            <video
              className="H-sec6-three-d1-vd1"
              src={video4}
              controls="controls"
              autoPlay="true"
            />
          </div> */}
        </div>

        <div className="H-sec6-three-d2">
          <div className="H-sec6-three-d2-img">
            <img
              className="H-sec6-three-d2-img-1"
              src="./PBimages/H-sec6-three-img.png"
            />
          </div>

          <div className="H-sec6-three-d2-content">
            <div className="H-sec6-three-d2-content-s1">
              <h3 id="h-tabv-sec3-h1">Exceptional Expertise</h3>
              <p>
                Our skilled team of IT professionals delivers top-notch<br></br>
                solutions tailored to your unique business needs.
              </p>
            </div>

            <div className="H-sec6-three-d2-content-s1">
              <h3>Client Satisfaction</h3>
              <p>
                Our commitment to customer satisfaction ensures exceptional
                <br></br>
                service delivery and long-term partnerships.
              </p>
            </div>

            <div className="H-sec6-three-d2-content-s1">
              <h3>Cutting-Edge Technology</h3>
              <p>
                We leverage the latest technologies to empower your business
                <br></br>
                with innovative solutions that stay ahead of the curve.
              </p>
            </div>

            <div className="H-sec6-three-d2-content-s1">
              <h3>Client-Centric Focus</h3>
              <p>
                We prioritize understanding your objectives, providing<br></br>
                personalized IT solutions that drive your success.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="H-sec7">
        <div className="H-sec7-one">
          <h1>
            {" "}
            <Counter endValue={80} />
          </h1>
          <p>Satistied Customers</p>
        </div>

        <div className="H-sec7-one">
          <h1>
            <Counter endValue={100} />
          </h1>
          <p>Projects in 26 Years</p>
        </div>

        <div className="H-sec7-one">
          <h1>
            {" "}
            <Counter endValue={200} />
          </h1>
          <p>Team Members</p>
        </div>

        <div className="H-sec7-one">
          <h1>
            {" "}
            <Counter endValue={10} />
          </h1>
          <p>Industry Verticals</p>
        </div>
      </div>

      <div className="H-sec8-one">
        <div className="H-sec8-one-d1">
          <span>INDUSTRIES</span>
        </div>

        <div className="H-sec8-one-d2">
          <h1>
            Industries Transformed<br></br> with<span> Our IT Solution</span>
          </h1>
          <img src="./PBimages/bb-dots-img.png" />
          <p>
            We leverage the latest technologies to transform businesses and
            organizations,<br></br> enabling them to work smarter and more
            efficiently.
          </p>
        </div>
      </div>

      <div onClick={() => nav("/domains")} className="H-sec8-two">
        <div className="h-sec8-two-d1 h-sec8-two-d1-mbvb">
          <img
            className="h-sec8-two-img1"
            src="PBimages/h-sec8-health-img1.png"
          />
          <h5>
            <a href="#Healthcare" className="custom-link">
              Healthcare
            </a>
          </h5>
          <p>
            Revamp your healthcare with state of the art solutions and expert
            support.
          </p>
        </div>

        <div className="h-sec8-two-d1 h-sec8-two-bdr">
          <img
            className="h-sec8-two-img1"
            src="PBimages/h-sec8-agri-img2.png"
          />
          <h5>
            <a href="#Agriculture" className="custom-link">
              Agriculture
            </a>
          </h5>
          <p>
            Partner with us to grow your business with cutting-edge solutions in
            agriculture.
          </p>
        </div>

        <div className="h-sec8-two-d1 h-sec8-two-d1-mbvb">
          <img className="h-sec8-two-img1" src="PBimages/h-sec8-fin-img3.png" />
          <h5>
            <a href="#Fintech" className="custom-link">
              Fintech
            </a>
          </h5>
          <p>
            Our fintech expertise helps you navigate the rapidly changing
            landscape of financial technology.
          </p>
        </div>

        <div className="h-sec8-two-d1 h-sec8-two-bdr">
          <img
            className="h-sec8-two-img1"
            src="PBimages/h-sec8-retail-img4.png"
          />
          <h5>
            <a href="#Retail&manufacturing" className="custom-link">
              Retail and Manufacturing
            </a>
          </h5>
          <p>
            Our solutions helps streamline processes and increase product
            quality from supply chain to production planning
          </p>
        </div>

        <div className="h-sec8-two-d1 h-sec8-two-d1-mbvb">
          <img
            className="h-sec8-two-img1"
            src="PBimages/h-sec8-game-img5.png"
          />
          <h5>
            <a href="#Games&entertainment" className="custom-link">
              Games & entertainment
            </a>
          </h5>
          <p>
            Modernized IT solutions bring your gaming and entertainment vision
            to life while boosting your customer experience
          </p>
        </div>

        <div className="h-sec8-two-d1 h-sec8-two-bdr">
          <img
            className="h-sec8-two-img1"
            src="PBimages/h-sec8-bank-img6.png"
          />
          <h5>
            <a href="#Banking" className="custom-link">
              Banking
            </a>
          </h5>
          <p>
            Delivering innovative and compliant solutions to support financial
            institutions in their digital transformation journey
          </p>
        </div>

        <div className="h-sec8-two-d1 h-sec8-two-d1-mbvb">
          <img className="h-sec8-two-img1" src="PBimages/h-sec8-gov-img7.png" />
          <h5>
            <a href="#Government" className="custom-link">
              Government
            </a>
          </h5>
          <p>
            Providing reliable and secure IT solutions to enhance public
            services and streamline operations.
          </p>
        </div>

        <div className="h-sec8-two-d1 h-sec8-two-bdr">
          <img className="h-sec8-two-img1" src="PBimages/h-sec8-utl-img8.png" />
          <h5>
            <a href="#Utilities" className="custom-link">
              Utilities
            </a>
          </h5>
          <p>
            Enhance customer comfort with our advanced IT solutions, providing
            constant access to essential amenities and utilities
          </p>
        </div>

        <div className="h-sec8-two-d1 tab-2">
          <img
            className="h-sec8-two-img1"
            src="PBimages/h-sec8-tele-img9.png"
          />
          <h5>
            <a href="#Telecommunincation" className="custom-link">
              Telecommunication
            </a>
          </h5>
          <p>
            We provide expert solutions for unique telecommunication industry
            challenges.
          </p>
        </div>

        <div className="h-sec8-btn">
          <button className="h-sec8-btn-b">
            View All <span>|</span>
            <img className="h-sec8-A1" src="./PBimages/WhiteArrow.png" />
          </button>
        </div>
      </div>

      <div className="H-sec9-one" id="client-testimonial">
        <div className="h-sec9-one-d1">
          <span>TESTIMONIALS</span>
        </div>

        <div className="h-sec9-one-d2">
          <h1>
            Hear from our{" "}
            <span>
              satisfied <br className="h-sec8-b"></br>clients
            </span>
          </h1>
          <img src="./PBimages/bb-dots-img.png" />
        </div>
      </div>

      <div className="h-sec9-two">
        <div className="h-sec9-two-d1">
          <img
            className="h-sec9-two-d1-img1"
            src="./PBimages/h-sec9-two-d1-img1-1.png"
            alt=""
          />

          <img
            className="h-sec9-two-mbv-img1"
            src="./PBimages/h-sec9-two-mbv-img1.png"
            alt=""
          />
        </div>

        <div className="h-sec9-two-d2">
          <p>
            "Teksett has been instrumental in the success of our manufacturing
            business. As a founder, I wanted a reliable IT partner to handle our
            technology needs so I could focus on growing the business. Teksett
            exceeded my expectations. They provided us with a comprehensive IT
            strategy tailored to our specific requirements, which has been
            pivotal in scaling our operations.
          </p>

          <h1>Rebacca (CEO)</h1>

          <h6>Manufacturing Client</h6>
        </div>
      </div>

      <div className="H-sec10-one">
        <div className="h-sec10-LA-d1">
          <img src="./PBimages/h-sec10-LA-img1.png" alt="" />
        </div>

        <div className="h-sec10-two">
          <div className="h-sec10-two-d1 h-sec10-dn">
            <div className="small-sec10-two">
              <div>
                <img
                  className="h-sec10-j-img1-in1"
                  src="./PBimages/h-sec10-j-img1.png"
                  alt=""
                />
              </div>

              <div className="h-sec10-3sec1">
                <h6> Daniel Martinez (Enterpreneur)</h6>
                <span>Finance Client</span>
              </div>
            </div>

            <div className="h-sec10-3sec2">
              <p>
                I recommend <b>Teksett</b> because of their agile way of working
                and focus on achieveing top-notch quality of the product.
                Communication in the project was clear and effective.
              </p>
            </div>
          </div>

          <div className="h-sec10-two-d1 h-sec10-dn">
            <div className="small-sec10-two">
              <div>
                <img
                  className="h-sec10-j-img1-in1"
                  src="./PBimages/h-sec10-j-img2.png"
                  alt=""
                />
              </div>

              <div className="h-sec10-3sec1">
                <h6>James Tjan (IT Manager)</h6>
                <span>Retail Client</span>
              </div>
            </div>

            <div className="h-sec10-3sec2">
              <p>
                "Our medium-sized enterprise has been partnering with Teksett
                for our IT solutions for over a year now, their customer service
                was exceptional, and they immediately put my mind at ease.
              </p>
            </div>
          </div>

          <div className="h-sec10-two-d1 bd3">
            <div className="small-sec10-two">
              <div>
                <img
                  className="h-sec10-j-img1-in1"
                  src="./PBimages/h-sec10-j-img3.png"
                  alt=""
                />
              </div>

              <div className="h-sec10-3sec1">
                <h6>Sophia Taylor (CIO)</h6>
                <span>Healthcare Client</span>
              </div>
            </div>

            <div className="h-sec10-3sec2">
              <p>
                Teksett's online IT solutions delivery has been a game-changer
                for us. The team at Teksett quickly assessed our needs and
                provided tailored solutions that fit perfectly within our
                budget.
              </p>
              <img
                className="h-se10-mbv"
                src="./PBimages/h-sec10-LA-img1.png"
                alt=""
              />
              <img
                className="h-se10-mbv"
                src="./PBimages/h-sec10-LR-img2.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="h-sec10-LA-d1">
          <img
            className="h-sec10-RA"
            src="./PBimages/h-sec10-LR-img2.png"
            alt=""
          />
        </div>
      </div>

      <div className="H-sec11-one">
        <div className="h-sec11-one-d1">
          <img
            className="h-sec11-one-d1-img"
            src="./PBimages/h-sec11-one-img.png"
            alt=""
          />
        </div>

        <div className="h-sec11-one-d2">
          <h1>
            Trust Our Expertise to <br className="ins-sec11-br1"></br>
            <span>Grow your Business</span>
          </h1>
          <img src="./PBimages/bb-dots-img.png" alt="" />

          <p>
            We have the skills and knowledge to provide you with <br></br> the
            right solutions to scale your business.
          </p>

          <Link className="h-sec11-btn" to={`/contactus`}>
            {" "}
            Get in Touch <span className="sp-div">|</span>
            <img className="h-sec1-A1" src="./PBimages/WhiteArrow.png" alt="" />
          </Link>

          {/* <button className="h-sec11-btn">
            Learn More <span>|</span>
            <img
              className="h-sec11-A1"
              src="./PBimages/WhiteArrow.png"
              alt=" "
            />
          </button> */}
        </div>
      </div>

      <div className="H-sec12-one" >
        <div className="H-sec12-one-d1">
          <span>BLOGS & ARTICLES</span>
        </div>

        <div className="H-sec12-one-d2">
          <h1>Latest Insights</h1>
          <img src="./PBimages/bb-dots-img.png" alt="" />
          <p id="home-blog-list">Thoughts, experiences and ideas of the Teksett team.</p>

        </div>
      </div>

      <div className="H-sec12-two">
        <div className="H-sec12-two-d1" >
          <img
            className="h-sec12-img1"
            src="./PBimages/H-sec12-two-img1.png"
            alt="Public clouds image"
          />
          <h6 id="H-sec12-two-d1-h6"  >
            Public clouds vs. own <br></br> servers: do the math!
          </h6>
         
          <section id="h-sec12-sp-p">
            <img
              className="h-sec12-circle"
              src="./PBimages/circle-img.png"
              alt=""
            />
            <Link
              className="h-sec12-link-L1"
              id="h-sec12-lin"
              to={`/home/blogexp4`}
            >
              Read More
            </Link>
            <img className="h-sec12-A" src="./PBimages/BlackArrow.png" alt="" />
          </section>
        </div>

        <div className="H-sec12-two-d1 H-sec12-two-d1-d2">
          <img
            className="h-sec12-img1"
            src="./PBimages/h-sec12-two-img2.png"
            alt="Cloud transformation images"
          />
          <h6 className="h-sec11-mb" >
            Cloud transformation, change management and exit strategy- A
            holistic approach.
          </h6>
          <section id="h-sec12-sp-p">
            <Link id="h-sec12-lin" to={`/home/blogexp1`}>
              <img
                className="h-sec12-circle"
                src="./PBimages/circle-img.png"
                alt=""
              />
              Read More
              <img
                className="h-sec12-A"
                src="./PBimages/BlackArrow.png"
                alt=""
              />
            </Link>
          </section>
        </div>

        <div className="H-sec12-two-d1">
          <img
            className="h-sec12-img1"
            src="./PBimages/h-sec12-two-img3.png"
            alt="Securely integrating"
          />
          <h6 className="h-sec11-mb">
            Securely integrating legacy application into the cloud with Azure
            AppProxy
          </h6>

          <section id="h-sec12-sp-p">
            <Link id="h-sec12-lin" to={`/home/blogexp2`}>
              <img
                className="h-sec12-circle"
                src="./PBimages/circle-img.png"
                alt=""
              />
              Read More
              <img
                className="h-sec12-A"
                src="./PBimages/BlackArrow.png"
                alt=""
              />
            </Link>
          </section>
        </div>

        <div className="H-sec12-two-d1 h-sec12-br">
          <img
            className="h-sec12-img1"
            src="./PBimages/h-sec12-two-img4.png"
            alt="What is IT architecture"
          />
          <h6 id="H-sec12-two-d1-h6-4">
            What is IT architecture and why does it matter?
          </h6>

          <section id="h-sec12-sp-p" className="h-sec12-sp-p-rm4">
            <Link id="h-sec12-lin" to={`/home/blogexp3`}>
              <img
                className="h-sec12-circle"
                src="./PBimages/circle-img.png"
                alt=""
              />
              Read More
              <img
                className="h-sec12-A"
                src="./PBimages/BlackArrow.png"
                alt=""
              />
            </Link>
          </section>
        </div>
      </div>
    </>
  );
};

function Counter({ endValue }) {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);
  let interval; // Define a single interval for all counters
  const divisionFactor = 5; // Common division factor for all counters
  const intervalSpeed = 500; // Adjust the interval speed to make it faster (e.g., 200ms)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        interval = setInterval(() => {
          setCount((prevCount) =>
            Math.min(prevCount + Math.ceil(endValue / divisionFactor), endValue)
          );
        }, intervalSpeed); // Adjust the interval speed here
      } else {
        clearInterval(interval); // Clear the interval when element is not intersecting
      }
    });
    observer.observe(counterRef.current);
    return () => {
      observer.disconnect();
      clearInterval(interval); // Clear the interval when the component is unmounted
    };
  }, [endValue, intervalSpeed]); // Include intervalSpeed in the dependencies

  return (
    <div
      className="counter"
      ref={counterRef}
      style={{
        transition: "all 1s ease", // Fast transition
      }}
    >
      <h1>{count}+</h1>
    </div>
  );
}

const ImLop = () => {
  const imagePaths = [
    "./PBimages/h-sec2-imgs-img2.png",
    "./PBimages/h-sec2-imgs-img3.png",
    "./PBimages/h-sec2-imgs-img4.png",
    "./PBimages/h-sec2-imgs-img5.png",
    "./PBimages/h-sec2-imgs-img6.png",
    "./PBimages/h-sec2-imgs-img7.png",
    "./PBimages/h-sec2-imgs-img8.png",
    "./PBimages/h-sec2-imgs-img9.png",
    "./PBimages/h-sec2-imgs-img10.png",
  ];
  const imagePathss = [
    ...imagePaths,
    ...imagePaths,
    ...imagePaths,
    ...imagePaths,
  ];
  return (
    <div className="image-scroll">
      <div className="h-sec2-imgs">
        {imagePathss.map((imagePath, index) => (
          <div key={index} className="h-sec2-img-d">
            <img className={"h-sec2-imgs"} src={imagePath} alt={"Image"} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
