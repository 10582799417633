import React, { useState } from "react";

import Wa from "../EXPimages/WhiteArrow.png";
import Ba from "../EXPimages/BlackArrow.png";
import Circle from "../EXPimages/circle-img.png";
import Bd from "../EXPimages/Blue-dot.png";

import Servexp1 from "../EXPimages/i-exp-img7.png";
import Servexp2 from "../EXPimages/dm-tele9-img9.png";
import {Link,  useNavigate } from "react-router-dom";

const Domult8 = () => {
  const nav =useNavigate()

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  
  return (
    <>
      <div className="XS-sec1">
        <div className="XS-sec1-one">
          <img className="XS-sec1-one-img" src={Servexp1} alt="expimg" />
        </div>

        <div className="XS-sec1-two">
          <span className="XS-sec1-two-sp1" onClick={() => nav("/domains")}>
            <span className="" style={{ padding: "2px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66966 11.2002L6.48437 10.3961L1.62896 5.60426L6.48437 0.804276L5.66965 0.000196528L-0.000464929 5.60426L5.66966 11.2002Z"
                  fill="black"
                />
              </svg>{" "}
            </span>
            Back to Domains
          </span>
          <h1 className="XS-sec1-two-h1">UTILITIES</h1>

          <img
            className="XS-sec1-one-img tabvimg"
            src={Servexp1}
            alt="expimg"
          />

          <h1 className="XS-sec1-two-h1-2">
            {" "}
            Powering Utilities Forward with Expert IT Solutions.
          </h1>
          <p className="XS-sec1-two-p1">
            Teksett excels in delivering tailored IT services and solutions for
            the utilities industry. With industry-specific expertise, robust
            infrastructure, and adherence to regulatory standards, we ensure
            optimal performance and reliability for utility operations
          </p>
          <h6 className="XS-sec1-two-h6">Top Benefits</h6>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Comprehensive Utilities Expertise
          </p>

          <br></br>

          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Regulatory Compliance and Data Integrity
          </p>

          <br></br>
          <p className="XS-sec1-two-sp2-p1">
            {" "}
            <img src={Bd} alt="" />
            Robust Infrastructure for Mission-Critical Systems
          </p>

          <h1 className="XS-sec1-two-h1-h2">
            Modern IT solutions for the Utilities Industry
          </h1>
          <p className="XS-sec1-two-p-p1">
            Teksett specializes in customized IT services for utilities,
            delivering optimal performance, reliability, and efficiency in
            water, natural gas, and electricity operations. With our extensive
            expertise, robust infrastructure, and regulatory compliance, we
            provide tailored solutions you can trust in the utilities industry.
          </p>

          <Link
            className="XS-sec1-two-btn1"
            to={`/contactus`}
          >
            {" "}
            Get in Touch <span className="S-sec2-btn-sp-d">|</span>
            <img className="whiteA" src={Wa} alt="" />
          </Link>

          {/* <button className="XS-sec1-two-btn1">
            Get in Touch <img className="whiteA" src={Wa} alt="" />
          </button> */}

          <h6 className="XS-sec1-two-h6-2">Frequently Asked Questions</h6>

          <p  onClick={() => setIsOpen1(!isOpen1)}  className="XS-sec1-two-sp-ps">
            <span className="XS-sec1-two-pl">+</span> How does Teksett's IT
            services enhance utility operations in the water,
            electricity,natural
          </p>
          {isOpen1 && (
          <p className="XS-sec1-two-sp-pA">
            Teksett offers tailored IT solutions for utilities, optimizing water
            distribution, grid monitoring, pipeline management, and safety
            protocols. Our services improve efficiency, reliability, and
            customer service across all utility sectors.
          </p>
          )}
          <hr></hr>

          <p   onClick={() => setIsOpen2(!isOpen2)} className="XS-sec1-two-sp-ps">
            <span>+</span> What technological advancements does Teksett bring to
            utility companies?
          </p>
          {isOpen2 && (
          <p className="XS-sec1-two-sp-pA">
            Teksett leverages advanced technologies like IoT sensors, data
            analytics, and smart metering to provide real-time insights,
            optimize resource allocation, detect leaks, and enhance overall
            utility operations efficiency.
          </p>
          )}
          <hr></hr>

          <p   onClick={() => setIsOpen3(!isOpen3)} className="XS-sec1-two-sp-ps">
            <span>+</span> How does Teksett assist utility companies in managing
            their infrastructure effectively?
          </p>
          {isOpen3 && (
          <p className="XS-sec1-two-sp-pA">
            Teksett provides comprehensive IT systems that enable utility
            companies to monitor and optimize infrastructure, including water
            distribution networks, electricity grids, and natural gas pipelines,
            improving operational efficiency and minimizing risks.
          </p>
          )}
          <hr></hr>

          <p   onClick={() => setIsOpen4(!isOpen4)} className="XS-sec1-two-sp-ps">
            <span>+</span>How does Teksett ensure reliable and uninterrupted
            utility services for customers?
          </p>
          {isOpen4 && (
          <p className="XS-sec1-two-sp-pA">
            By leveraging robust infrastructure and expertise in IT solutions,
            Teksett helps utility companies proactively monitor and manage their
            systems, ensuring uninterrupted service delivery of water,
            electricity, and natural gas to customers.
          </p>
          )}
          <hr></hr>
        </div>
      </div>

      <div className="XS-sec2">
        <div className="XS-sec2-in1">
          <span>READ MORE</span>
        </div>

        <div className="XS-sec2-in2">
          <div className="XS-sec2-in2-d1">
            <h1>Telecom</h1>
            <p className="XS-sec2-in2-d1-p1">
              At Teksett, we understand the unique needs if fintech companies.
              Our experienced recruiters specialize in finding the best talent
              for fintech positions, including IT support, software development,
              project management, and more.
            </p>

            <Link
              className="XS-sec2-in2-d1-sp1"
              to={`/domains/domtele9`}
            
            >
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </Link>
            {/* <span className="XS-sec2-in2-d1-sp1">
              <img className="XS-sec2-in2-d1-CI" src={Circle} alt="" />
              Read More
              <img className="XS-sec2-in2-d1-ba" src={Ba} alt="" />
            </span> */}
          </div>

          <div className="XS-sec2-in2-d2">
            <img className="XS-sec2-in2-d2-img" src={Servexp2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Domult8;
